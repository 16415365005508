import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  AngularFireDatabase,
  AngularFireObject,
  AngularFireList,
} from "@angular/fire/database";
import firebase from "firebase/app";
import { UtilService } from "./util.service";
import { TrackingService } from "./tracking.service";
import { AuthService } from "./auth.service";
import { ProfileService } from "./profile.service";
import { ClubService } from "./club.service";
import { ApiService } from "./api.service";
import { TranslateService } from "@ngx-translate/core";
import { TdDialogService } from "@covalent/core/dialogs";
import { MatButtonToggleAppearance } from "@angular/material/button-toggle";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { Subject } from "rxjs";
import { AudioService } from "./audio.service";
import { LeagueService } from "./league.service";
import * as math from "mathjs/dist/math";
import { PlayerMovesComponent } from "../shared/player-moves/player-moves.component";
import { RoutingService } from "./routing.service";
import { find, map } from "rxjs/operators";
import { MaxPlayersComponent } from "../shared/max-players/max-players.component";

@Injectable()
export class TournamentService {
  edits: any[] = [];
  currentTab: string = "blinds";
  payoutsTab: number = 0;
  tournamentAF: AngularFireObject<any>;
  tournament: Observable<any>;
  paramsAF: AngularFireObject<any>;
  params: Observable<any>;
  liveAF: AngularFireObject<any>;
  live: Observable<any>;
  levelsAF: AngularFireList<any>;
  levels: Observable<any[]>;
  playersAF: AngularFireList<any>;
  players: Observable<any[]>;
  tablesAF: AngularFireList<any>;
  tables: Observable<any[]>;
  latestTables: any[];
  tableNamesAF: AngularFireList<any>;
  tableNamesVC: Observable<any[]>;
  tableNames: any = {};
  logAF: AngularFireList<any>;
  log: Observable<any[]>;
  latestLog: any[];
  logSub: any;
  payoutsAF: AngularFireList<any>;
  payouts: Observable<any[]>;
  latestTourn: any;
  latestLevels: any;
  structure: [any];
  struct: boolean;
  paramsUpdateValues: any;
  latestPlayers: any[];
  possibleBlinds: any[];
  allPossibleBlinds: any[];
  levelsPath: string;
  paramsPath: string;
  chipDistThresh: number = 6;
  mainTab: string = "blinds";
  mainIndex: number = 0;
  blindsTab: number = 0;
  playersTab: number = 0;
  latestPayouts: any[];
  tournID: string;
  tournamentLogID: string;
  baseRef: any;
  tournRef: any;
  clock: any = {};
  delta: any;
  latestLive: any;
  baseSec: number;
  slider: any = {};
  sliderLevel: any = {};
  sounds: any;
  nextBreak: number;
  private infoCycle: any;
  private needInfosInit: boolean = true;
  rootRef: any;
  infos: Observable<any[]>;
  infoArr: any[];
  private infoLoop = new Subject<any>();
  infoWidth: number;
  tournamentSub: Subscription;
  tickObs: Observable<boolean>;
  tickSubj = new Subject<boolean>();
  localPayouts: any[];
  awardDelta: number;
  percentDelta: number;
  needPayoutsInit: boolean;
  toRegister: any;
  toUnregister: string;
  playerMoves: any[];
  tableBreaking: number;
  settingsOpen: boolean = false;
  blindTabVisit: boolean = false;
  leaguesToAdd: any;
  loadingRegister: boolean = false;
  loadingMovePlayer: boolean = false;
  loadingUnRegisterPlayer: boolean = false;
  loadingEliminationPlayer: boolean = false;
  loadingAddTable: boolean = false;
  loadingDrawSeats: boolean = false;
  lastActionIsRegister: boolean = false;

  constructor(
    private db: AngularFireDatabase,
    private util: UtilService,
    private tracking: TrackingService,
    private auth: AuthService,
    private profServ: ProfileService,
    private clubServ: ClubService,
    private translate: TranslateService,
    private dialogServ: TdDialogService,
    private snackBar: MatSnackBar,
    private audio: AudioService,
    private leagueServ: LeagueService,
    private dialog: MatDialog,
    private routing: RoutingService,
    private api: ApiService
  ) {
    this.rootRef = firebase.database().ref();
    this.infos = this.infoLoop.asObservable();
    this.tickObs = this.tickSubj.asObservable();
  }
  async checkOldChipset() {
    await this.rootRef
      .child("tournaments")
      .child(this.tournID)
      .child("params")
      .child("chipset")
      .once("value", async (snapshot) => {
        if (snapshot.exists()) {
          let chipset = snapshot.val();

          // if string convert to array
          if (typeof chipset == "string") {
            // remove trailing comma if exists
            if (chipset.substr(-1) == ",") chipset = chipset.slice(0, -1);

            if (this.latestTourn?.params) {
              this.latestTourn.params.chipset = chipset
                .split(",")
                .map((chip: string) => parseFloat(chip));
            }
            await this.rootRef
              .child("tournaments")
              .child(this.tournID)
              .child("params")
              .child("chipset")
              .set(chipset.split(",").map((chip: string) => parseFloat(chip)));
          }
        }
      });
  }
  async init(tournID: string) {
    this.needInfosInit = true;
    this.needPayoutsInit = true;
    this.mainTab = "clock";
    this.tournID = tournID;
    await this.checkOldChipset();
    let tournPath = "tournaments/" + tournID;
    this.levelsPath = "structures/" + tournID + "/levels";
    this.paramsPath = tournPath + "/params";
    let playersPath = tournPath + "/players";
    let tablesPath = tournPath + "/tables";
    let tableNamesPath = tournPath + "/tableNames";
    let livePath = tournPath + "/live";
    let logPath = `logs/tournaments/${tournID}`;
    let infosPath = tournPath + "/infos";
    let payoutsPath = "payouts/" + tournID;
    this.tournamentAF = this.db.object(tournPath);
    this.tournament = this.tournamentAF.valueChanges();
    this.levelsAF = this.db.list(this.levelsPath);
    this.levels = this.levelsAF.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action) => ({
          key: action.key,
          ...action.payload.val(),
        }));
      })
    );
    this.paramsAF = this.db.object(this.paramsPath);
    this.params = this.paramsAF.valueChanges();
    this.playersAF = this.db.list(playersPath);
    this.players = this.playersAF.valueChanges();
    this.tablesAF = this.db.list(tablesPath);
    this.tables = this.tablesAF.valueChanges();
    let tablesSub = this.tables.subscribe((results) => {
      this.latestTables = results;
      if (results) {
        for (let i = 0; i < results.length; i++) {
          this.edits.push({ edit: false });
        }
      }
    });
    this.tableNamesAF = this.db.list(tableNamesPath);
    this.tableNamesVC = this.tableNamesAF.valueChanges();
    let tableNamesSub = this.tableNamesVC.subscribe((results) => {
      if (results) {
        this.tableNames = results;
      }
    });
    this.liveAF = this.db.object(livePath);
    this.live = this.liveAF.valueChanges();
    this.logAF = this.db.list(logPath);
    this.log = this.logAF.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action) => ({
          key: action.key,
          ...action.payload.val(),
        }));
      })
    );
    this.payoutsAF = this.db.list(payoutsPath);
    this.payouts = this.payoutsAF.valueChanges();
    this.needInfosInit = true;
    this.tournamentSub = this.tournament.subscribe((results) => {
      if (results && results.tournID) {
        if (results.players) {
          this.latestPlayers = this.util.getObjArray(results.players);
          if (this.tableNames && Object.keys(this.tableNames).length) {
            this.latestPlayers.forEach((player) => {
              if (this.tableNames[player.table - 1])
                player.TableName = this.tableNames[player.table - 1];
            });
          }
        } else if (this.latestTourn?.players) {
          this.latestPlayers = null;
        }
        this.latestTourn = results;
        this.latestLive = results.live;
        if (results.params && results.params.tournInit)
          this.changeParam("tournInit", null);
        this.setClock();
        if (
          results.params &&
          (results.params.seated || this.latestLive.seated) &&
          this.needInfosInit
        )
          this.checkBalance();
        if (results.live && this.mainTab != "clock") this.tick();
        this.initInfoCycle();
        if (!results.params.manageRegistrations && !results.params.actPlayers) {
          this.changeParam("actPlayers", results.params.expPlayers);
        }
        if (this.toRegister) {
          let toRegister: any = this.util.copyObject(this.toRegister);
          this.toRegister = null;
          if (this.seatAvailable(toRegister)) this.register(toRegister);
        }
        if (this.toUnregister) {
          if (this.latestTourn && this.latestTourn.players) {
            let toUnregister = this.toUnregister;
            this.toUnregister = null;
            this.unregister(toUnregister);
          }
        }
        if (!results.params.registrations)
          this.changeParam("registrations", "open");
        if (!results.params.tournamentType)
          if (results.params.rebuyTournament)
            this.changeParam("tournamentType", "rebuy_tournament");
          else this.changeParam("tournamentType", "reentry_tournament");
        if (
          results.params.tournamentType == "reentry_tournament" &&
          !results.params.reentries &&
          results.params.reentries != 0
        )
          this.changeParam("reentries", 0);
        if (
          results.params.tournamentType == "reentry_tournament" &&
          !results.params.reentriesManual &&
          results.params.reentriesManual != false
        )
          this.changeParam("reentriesManual", false);
        if (!results.params.reentryChips && results.params.startingStack) {
          this.changeParam("reentryChips", results.params.startingStack);
          this.changeParam("reentryChipsManual", false);
        }
        if (!results.params.reentryCost && results.params.buyin) {
          this.changeParam("reentryCost", results.params.buyin);
        }

        this.util.loading = false;
        this.checkForGhosts();
      } else {
        this.latestTourn = null;
        this.latestLive = null;
      }
    });
    let levelsSub = this.levels.subscribe((results) => {
      if (results) {
        this.buildStructure(results);
        this.latestLevels = results;
      }
    });
    let payoutsSub = this.payouts.subscribe((results) => {
      if (results) {
        this.latestPayouts = results;
        if (this.needPayoutsInit) {
          this.setLocalPayouts();
          this.needPayoutsInit = false;
        }
        if (this.latestPlayers && this.latestPlayers.length > 0) {
          this.updateStacks();
        }
      }
    });
    this.baseRef = firebase.database().ref();
    this.tournRef = this.baseRef.child("tournaments").child(tournID);
    this.auth.addSub(this.tournamentSub);
    this.auth.addSub(levelsSub);
    this.auth.addSub(payoutsSub);
    this.auth.addSub(tablesSub);
    this.initClock();
    this.latestLog = [];
    this.db
      .object(`structures/${tournID}`)
      .valueChanges()
      .subscribe((result) => {
        if (result && result["levels"]) this.struct = true;
        else this.struct = false;
      });
  }
  initLogs() {
    this.logSub = this.log.subscribe((results) => {
      this.latestLog = results;
      this.tournamentLogID = this.tournID;
    });
    this.auth.addSub(this.logSub);
  }
  unsubscribeLogs() {
    this.logSub.unsubscribe();
  }
  clearTournament() {
    if (this.tournamentSub) this.tournamentSub.unsubscribe();
    this.currentTab = "blinds";
    this.latestTourn = null;
    this.levels = null;
    this.latestLevels = null;
    this.structure = null;
    this.params = null;
    this.paramsUpdateValues = null;
    this.latestPlayers = null;
    this.possibleBlinds = null;
    this.allPossibleBlinds = null;
    this.chipDistThresh = 6;
    this.mainTab = "blinds";
    this.blindsTab = 0;
    this.playersTab = 0;
    this.tables = null;
    this.latestPayouts = null;
    this.latestLive = null;
    this.sliderLevel = null;
    this.nextBreak = null;
    this.needInfosInit = true;
    this.players = null;
    this.live = null;
    this.log = null;
    this.payouts = null;
    this.mainIndex = 0;
    this.clock = {};
    clearInterval(this.infoCycle);
    this.needInfosInit = true;
  }
  createTournament(tournName, tournDate, tournTime, params, template?: any) {
    this.util.loading = true;
    if (this.clubServ.latestClub) {
      if (template && template.params) {
        params = template.params;
        params.prizepool = 0;
        if (!params.tournamentType)
          if (params.rebuyTournament)
            params.tournamentType = "rebuy_tournament";
          else params.tournamentType = "freezeout";
      } else {
        params.addedPrize = 0;
        params.extraSeats = 0;
        params.perTable = 9;
        params.numberPlayersFinalTable = params.perTable;
        params.tournInit = true;
        params.calcPlacesPaidManual = false;
        params.payoutManual = false;
        if (params.buyin) {
          params.rebuyCost = params.buyin;
          params.addonCost = params.buyin;
          params.reentryCost = params.buyin;
        }
      }
      const playLimit: number = this.clubServ.getPlayerLimit();
      if (!params.manageRegistrations) {
        if (playLimit && params.expPlayers > playLimit)
          params.actPlayers = playLimit;
        else params.actPlayers = params.expPlayers;
      } else {
        if (playLimit) {
          params.maxPlayers = this.clubServ.getPlayerLimit();
        } else {
          params.maxPlayers = 9999;
        }
      }
      if (!params.reentryChips && params.startingStack)
        params.reentryChips = params.startingStack;
      if (!params.reentryCost && params.buyin)
        params.reentryCost = params.buyin;
      params.tournStatus = "Scheduled";

      let tournTimestamp = tournDate.getTime() + tournTime.getTime();
      tournTimestamp -= tournTime.getTimezoneOffset() * 60 * 1000;
      var newTournKey = firebase
        .database()
        .ref()
        .child("tournaments")
        .push().key;
      this.logAF = this.db.list(`logs/tournaments/${newTournKey}`);
      let updates: any = {};
      let leagues: any;
      if (this.leaguesToAdd) {
        leagues = this.util.copyObject(this.leaguesToAdd);
        this.leaguesToAdd = null;
      }
      updates["tournaments/" + newTournKey] = {
        clubID: this.clubServ.clubID,
        tournID: newTournKey,
        tournName: tournName,
        tournTimestamp: tournTimestamp,
        params: params,
        owner: this.clubServ.latestClub.owner,
        playerCount: 0,
        registrations: "open",
        live: {
          index: 0,
          seconds: 0,
          running: false,
          started: false,
          out: 0,
          rebuys: 0,
          addons: 0,
          undoIdx: 0,
          reentries: 0,
        },
      };
      updates["clubs/" + this.clubServ.clubID + "/tournaments/" + newTournKey] =
        {
          tournID: newTournKey,
          tournName: tournName,
          tournTimestamp: tournTimestamp,
          sort: -tournTimestamp,
          params: params,
        };
      if (leagues) {
        updates["tournaments/" + newTournKey].leagues = leagues;
        updates[
          "clubs/" + this.clubServ.clubID + "/tournaments/" + newTournKey
        ].leagues = leagues;
        for (let l in leagues)
          updates["leagues/" + l + "/tournaments/" + newTournKey] = {
            tournID: newTournKey,
            tournName: tournName,
          };
      }
      if (template && template.structure)
        updates["structures/" + newTournKey + "/levels"] = template.structure;

      updates["profiles/" + this.profServ.uid + "/defaults/anteType"] =
        params.anteType || "standard";
      updates["profiles/" + this.profServ.uid + "/defaults/chipset"] =
        params.chipset;
      updates["profiles/" + this.profServ.uid + "/defaults/duration"] =
        params.duration;
      updates["profiles/" + this.profServ.uid + "/defaults/expPlayers"] =
        params.expPlayers;
      updates["profiles/" + this.profServ.uid + "/defaults/initSmallBlind"] =
        params.initSmallBlind;
      updates["profiles/" + this.profServ.uid + "/defaults/managePayouts"] =
        params.managePayouts;
      updates[
        "profiles/" + this.profServ.uid + "/defaults/manageRegistrations"
      ] = params.manageRegistrations;
      updates["profiles/" + this.profServ.uid + "/defaults/tournamentType"] =
        params.tournamentType;
      updates["profiles/" + this.profServ.uid + "/defaults/rebuyChips"] =
        params.rebuyChips;
      updates["profiles/" + this.profServ.uid + "/defaults/rebuyChips"] =
        params.reentryChips;
      updates["profiles/" + this.profServ.uid + "/defaults/addonChips"] =
        params.addonChips;
      updates["profiles/" + this.profServ.uid + "/defaults/startingStack"] =
        params.startingStack;
      if (params.rebuys)
        updates["profiles/" + this.profServ.uid + "/defaults/rebuyRatio"] =
          params.rebuys / params.expPlayers;
      if (params.addons)
        updates["profiles/" + this.profServ.uid + "/defaults/addonRatio"] =
          params.addons / params.expPlayers;
      if (params.addonChips && params.startingStack)
        updates["profiles/" + this.profServ.uid + "/defaults/addonChipsRatio"] =
          params.addonChips / params.startingStack;
      params.reentryRatio =
        params.reentries && params.expPlayers
          ? params.reentries / params.expPlayers
          : 0.333;
      if (params.buyin) {
        updates["profiles/" + this.profServ.uid + "/defaults/buyin"] =
          params.buyin;
        updates["profiles/" + this.profServ.uid + "/defaults/rebuyCost"] =
          params.buyin;
        updates["profiles/" + this.profServ.uid + "/defaults/addonCost"] =
          params.buyin;
        updates["profiles/" + this.profServ.uid + "/defaults/reentryCost"] =
          params.buyin;
      }

      this.rootRef.update(updates).then((data) => {
        this.clubServ.changeTournStatusInClub(newTournKey, "Scheduled");
        this.logEntry("CREATE_TOURNAMENT");
        this.routing.toTournament(newTournKey);
        this.api.sendEvent(
          "EVT_TOURNAMENT_CREATED",
          this.profServ.latestProfile
        );
      });
    }
  }
  duplicateTournament() {
    let dupRef = this.rootRef.child("tournaments").push();
    let tObj: any = {
      clubID: this.latestTourn.clubID,
      tournID: dupRef.key,
      tournName: this.latestTourn.tournName + "*",
      tournTimestamp: Date.now(),
      params: this.latestTourn.params,
      owner: this.latestTourn.owner,
      playerCount: 0,
      registrations: "open",
      live: {
        index: 0,
        seconds: 0,
        running: false,
        started: false,
        out: 0,
        rebuys: 0,
        addons: 0,
      },
    };
    tObj.params.addedPrize = 0;
    tObj.params.extraSeats = 0;
    tObj.params.undoIdx = 0;
    tObj.params.tournInit = true;
    let clubTourn: any = {
      params: tObj.params,
      sort: -tObj.tournTimestamp,
      tournID: tObj.tournID,
      tournName: tObj.tournName,
      status: "Scheduled",
      tournTimestamp: tObj.tournTimestamp,
    };
    if (
      this.latestTourn &&
      this.latestTourn.params.manageRegistrations &&
      this.latestTourn.players
    ) {
      tObj.playerCount = this.latestTourn.playerCount;
      clubTourn.playerCount = this.latestTourn.playerCount;
      tObj.players = this.util.copyObject(this.latestTourn.players);
      for (let p in tObj.players) {
        tObj.players[p].addon = 0;
        tObj.players[p].rebuys = 0;
        tObj.players[p].kos = 0;
        tObj.players[p].regStatus = "Registered";
        tObj.players[p].finish = null;
        tObj.players[p].stack = this.latestTourn.params.startingStack;
        tObj.players[p].seatSort = null;
        tObj.players[p].payout = null;
        tObj.players[p].killer = null;
        tObj.players[p].killerID = null;
        tObj.players[p].points = null;
        tObj.players[p].leagueFinish = null;
      }
    }
    dupRef.set(tObj).then((data) => {
      this.routing.toTournament(dupRef.key);
    });
    this.rootRef
      .child("clubs")
      .child(this.latestTourn.clubID)
      .child("tournaments")
      .child(clubTourn.tournID)
      .set(clubTourn);
    this.rootRef
      .child("structures")
      .child(clubTourn.tournID)
      .child("levels")
      .set(this.latestLevels);
    if (this.latestTourn.params.managePayouts)
      this.rootRef
        .child("payouts")
        .child(clubTourn.tournID)
        .set(this.latestPayouts);
  }
  goTab(tab: string) {
    this.currentTab = tab;
  }
  buildStructure(results: any) {
    this.structure = <any>[];
    let blinds: [any] = <any>[];
    let breaks: [any] = <any>[];
    let breakLevels = [];
    for (let level of results) {
      if (level.sb) blinds.push(level);
      else {
        if (
          level.afterLevel >= 0 &&
          breakLevels.indexOf(level.afterLevel) == -1
        ) {
          breaks.push(level);
          breakLevels.push(level.afterLevel);
        }
      }
    }

    blinds.sort(this.blindsSort);
    breaks.sort((a, b) => {
      return a.afterLevel - b.afterLevel;
    });
    let nextBreak = breaks.shift();
    let num = 0;
    let breakStart = 0;
    for (let blevel of blinds) {
      if (nextBreak && nextBreak.afterLevel == num) {
        nextBreak.breakStart = breakStart;
        this.structure.push(nextBreak);
        breakStart += nextBreak.levelTime * 60;
        nextBreak = breaks.shift();
      }
      breakStart += blevel.levelTime * 60;
      num++;
      blevel.num = num;
      blevel.kLevel = this.kBlinds(blevel);
      this.structure.push(blevel);
    }
    let elapsed_num: number = 0;
    for (let i = 0; i < this.structure.length; i++) {
      const level = this.structure[i];
      elapsed_num += level.levelTime;
      this.structure[i].elapsed = this.util.timeText(elapsed_num, true);
    }
  }
  private blindsSort(a, b) {
    function getBlindIndex(level: any) {
      let idx = level.sb + level.bb / 10;
      if (level.ante) idx += level.ante / 100;
      return idx;
    }
    return getBlindIndex(a) - getBlindIndex(b);
  }
  private kFormat(num: number) {
    if (num < 1000 || (num < 10000 && num % 1000 != 0)) return num.toString();
    else {
      let suffix = "";
      let divisor = 1;
      if (num >= 1000) {
        suffix = "K";
        divisor = 1000;
      }
      if (num >= 1000000) {
        suffix = "M";
        divisor = 1000000;
      }
      num /= divisor;
      if (num < 10) num = math.round(num, 2);
      else num = math.round(num, 1);
      return num.toString() + suffix;
    }
  }

  kBlinds(level: any) {
    let retValues = <any>{};
    if (
      (!level.ante && level.sb >= 10000) ||
      (level.ante && level.sb >= 4000)
    ) {
      retValues.sb = this.kFormat(level.sb);
      retValues.bb = this.kFormat(level.bb);
      if (level.ante) retValues.ante = this.kFormat(level.ante);
    } else {
      retValues.sb = level.sb;
      retValues.bb = level.bb;
      if (level.ante) retValues.ante = level.ante;
    }
    return retValues;
  }
  perTableChange(oldPerTable: number, newPerTable: number) {
    let tournUpdate: any = {};
    if (oldPerTable != newPerTable) {
      if (newPerTable > oldPerTable) {
        for (let t in this.latestTourn.tables) {
          let table: any = this.latestTourn.tables[t];
          if (!table.avail) {
            table.avail = [];
          }
          let start: number = oldPerTable;
          while (start < newPerTable) {
            if (table.seats.length <= start) {
              table.seats.push("-");
              table.avail.push(start);
            }
            start++;
          }
          tournUpdate["tables/" + t + "/avail"] = table.avail;
          tournUpdate["tables/" + t + "/seats"] = table.seats;
        }
      } else {
        for (let t in this.latestTourn.tables) {
          let table: any = this.latestTourn.tables[t];
          if (table.avail && Array.isArray(table.avail)) {
            let start: number = oldPerTable - 1;
            while (start >= newPerTable) {
              let found = table.avail.indexOf(start);
              if (found != -1) {
                table.avail.splice(found, 1);
                table.seats[start] = null;
              }
              start--;
            }
            tournUpdate["tables/" + t + "/avail"] = table.avail;
            tournUpdate["tables/" + t + "/seats"] = table.seats;
          }
        }
      }
      this.tournamentAF.update(tournUpdate);
    }
  }
  changeParam(param, val) {
    this.clearParamUpdateValues();
    if (param == "actPlayers") {
      if (!this.latestTourn.params.actPlayersDirty)
        this.addParamUpdate("actPlayersDirty", true);

      if (
        this.clubServ.latestClub.player_limit > 0 &&
        val > this.clubServ.latestClub.player_limit
      )
        val = this.clubServ.latestClub.player_limit;
    }
    if (
      param == "maxPlayers" &&
      this.clubServ.latestClub.player_limit > 0 &&
      val > this.clubServ.latestClub.player_limit
    )
      val = this.clubServ.latestClub.player_limit;

    if (param == "expPlayers" && !this.latestTourn.params.actPlayersDirty)
      this.addParamUpdate("actPlayers", val);
    if (param == "perTable" && this.latestLive.seated)
      this.perTableChange(this.latestTourn.params.perTable, val);
    if (
      param === "startingStack" &&
      val &&
      this.latestLive &&
      !this.latestLive.started &&
      this.latestTourn.players
    ) {
      var pUpdate: any = {};
      for (let p in this.latestTourn.players) {
        pUpdate["players/" + p + "/stack"] = val;
      }
      this.tournamentAF.update(pUpdate);
    }
    if (
      param == "tournamentType" &&
      val == "reentry_tournament" &&
      this.latestTourn.params.tournamentType != "reentry_tournament" &&
      this.latestLive.out
    ) {
      let pUpdate: any = {};
      for (let p in this.latestTourn.players) {
        if (this.latestTourn.players[p].finish) {
          this.latestTourn.players[p].reentryPossible = true;
          pUpdate["players/" + p + "/reentryPossible"] = true;
        }
      }
      this.tournamentAF.update(pUpdate);
    }
    if (
      param == "tournamentType" &&
      val != "reentry_tournament" &&
      this.latestTourn.params.tournamentType == "reentry_tournament" &&
      this.latestLive.out
    ) {
      let pUpdate: any = {};
      for (let p in this.latestTourn.players) {
        if (this.latestTourn.players[p].reentryPossible) {
          this.latestTourn.players[p].reentryPossible = null;
          pUpdate["players/" + p + "/reentryPossible"] = null;
        }
      }
      this.tournamentAF.update(pUpdate);
    }
    this.addParamUpdate(param, val);
    this.calcSmallBlind();
    this.calcStartingStack();
    this.calcRebuyChips();
    this.calcAddonChips();
    this.calcRebuys();
    this.calcAddons();
    this.setExpectedTotalChips();
    this.setPossibleBlinds();
    this.calcLevelTime();
    this.calcStructure();
    this.calcPlacesPaid();
    this.calcPrizepool();
    this.calcPayouts();
    this.updateParams();
    this.setActualTotalChips();
  }
  clearParamUpdateValues() {
    this.paramsUpdateValues = {};
  }
  addParamUpdate(param, val) {
    if (
      this.latestTourn &&
      this.latestTourn.params &&
      val != this.latestTourn.params[param]
    ) {
      this.paramsUpdateValues[param] = val;
      this.latestTourn.params[param] = val;
    }
  }
  calcSmallBlind() {
    if (!this.latestTourn.params.initSmallBlindManual) {
      this.addParamUpdate("initSmallBlind", this.latestTourn.params.chipset[0]);
    }
  }
  getStartingStack(params: any) {
    return params.initSmallBlind * this.getPerfectMult(params);
  }
  calcStartingStack() {
    if (!this.latestTourn.params.startingStackManual) {
      this.addParamUpdate(
        "startingStack",
        this.getStartingStack(this.latestTourn.params)
      );
    }
  }
  getPerfectMult(params): number {
    let ratio: number =
      (params.duration * 60) / params.expPlayers + params.duration * 3;
    if (params.tournamentType != "freezeout") ratio -= 2;
    let multSB: number;
    if (ratio < 20) multSB = 100;
    else if (ratio < 35) multSB = 200;
    else if (ratio < 45) multSB = 300;
    else if (ratio < 80) multSB = 400;
    else multSB = 600;
    return multSB;
  }
  calcRebuyChips() {
    if (!this.latestTourn.params.rebuyChipsManual) {
      this.addParamUpdate("rebuyChips", this.latestTourn.params.startingStack);
    }
  }
  getRoundedAddonChips(params: any) {
    if (this.profServ.latestProfile) {
      let rounder;
      for (rounder of params.chipset) {
        if (rounder >= params.startingStack * 0.1) break;
      }
      let addonchips: number = this.util.roundTo(
        params.startingStack *
          this.profServ.latestProfile.defaults.addonChipsRatio,
        rounder
      );
      if (!addonchips) addonchips = 0;
      return addonchips;
    } else return 0;
  }
  calcAddonChips() {
    if (!this.latestTourn.params.addonChipsManual) {
      this.addParamUpdate(
        "addonChips",
        this.getRoundedAddonChips(this.latestTourn.params)
      );
    }
  }
  calcRebuys() {
    if (!this.latestTourn.params.rebuysManual) {
      this.addParamUpdate("rebuys", this.latestTourn.params.expPlayers);
    }
  }
  calcAddons() {
    if (!this.latestTourn.params.addonsManual) {
      this.addParamUpdate(
        "addons",
        Math.round(this.latestTourn.params.expPlayers * 0.75)
      );
    }
  }
  setExpectedTotalChips() {
    this.latestTourn.params.expTotalChips = this.totalChips(
      this.latestTourn.params.expPlayers,
      this.latestTourn.params.rebuys,
      this.latestTourn.params.addons,
      this.latestTourn.params.reentries || 0
    );
  }
  setActualTotalChips() {
    let actTotalChips: number;
    if (this.latestTourn.params.manageRegistrations)
      actTotalChips = this.totalChips(
        this.getPlayersCount(),
        this.latestLive.rebuys,
        this.latestLive.addons,
        this.latestLive.reentries
      );
    else
      actTotalChips = this.totalChips(
        this.getPlayersCount(),
        this.latestLive.rebuys,
        this.latestLive.addons,
        this.latestLive.reentries
      );

    if (
      actTotalChips != this.latestLive.totalChips &&
      (actTotalChips || actTotalChips == 0)
    )
      this.liveAF.update({
        totalChips: actTotalChips,
      });
  }
  getPossibleBlinds(params: any) {
    const tmpChipset: number[] = params.chipset.slice();
    let possibleBlinds: any[] = [];
    let allPossibleBlinds: any[] = [];
    let finalSmallBlind: number = params.expTotalChips / 40;
    let maxVal: number = Math.max(...params.chipset);
    while (tmpChipset.length < 6) {
      if (maxVal.toString().startsWith("25")) {
        maxVal *= 4;
      } else {
        maxVal *= 5;
      }
      tmpChipset.push(maxVal);
    }
    const multiplier: any[] = [1, 2, 3, 4, 6, 8, 10, 12];
    for (let mult of multiplier)
      for (let chip of tmpChipset) {
        var blind = Math.round(mult * chip * 100) / 100;
        if (
          possibleBlinds.indexOf(blind) == -1 &&
          blind <= finalSmallBlind &&
          blind >= params.initSmallBlind
        )
          possibleBlinds.push(blind);
        if (
          allPossibleBlinds.indexOf(blind) == -1 &&
          blind >= params.initSmallBlind
        )
          allPossibleBlinds.push(blind);
      }
    this.util.numSort(possibleBlinds);
    this.util.numSort(allPossibleBlinds);
    return {
      possibleBlinds: possibleBlinds,
      allPossibleBlinds: allPossibleBlinds,
    };
  }
  setPossibleBlinds() {
    let blinds = this.getPossibleBlinds(this.latestTourn.params);
    this.possibleBlinds = blinds.possibleBlinds;
    this.allPossibleBlinds = blinds.allPossibleBlinds;
  }

  getLevelTime(params: any, possibleBlinds: any[]) {
    let numLevels: number = possibleBlinds.length;
    if (!params.levelTimeManual && numLevels) {
      if (params.antes) numLevels++; // add one lvel for antes tournaments because when antes are added blinds stay the same
      let idealLevelTime: number = (params.duration * 60) / numLevels;
      if (idealLevelTime <= 15) idealLevelTime = Math.ceil(idealLevelTime);
      else if (idealLevelTime <= 20)
        idealLevelTime = Math.ceil(idealLevelTime / 2) * 2;
      else if (idealLevelTime <= 60)
        idealLevelTime = Math.ceil(idealLevelTime / 5) * 5;
      else idealLevelTime = Math.ceil(idealLevelTime / 15) * 15;
      return idealLevelTime;
    } else return null;
  }
  calcLevelTime() {
    let lt = this.getLevelTime(this.latestTourn.params, this.possibleBlinds);
    if (lt) this.addParamUpdate("levelTime", lt);
  }
  updateManPayouts() {
    for (let p in this.latestPayouts) {
      if (this.latestPayouts[p].percent) {
        let award: number = math.round(
          (this.latestTourn.params.prizepool * this.latestPayouts[p].percent) /
            100,
          2
        );
        if (award != this.latestPayouts[p].award)
          this.payoutsAF.update(p, { award: award });
      }
    }
    setTimeout(() => {
      this.setLocalPayouts();
    });
  }
  updateParams() {
    if (this.paramsUpdateValues) {
      this.paramsAF.update(this.paramsUpdateValues);
      this.rootRef
        .child("profiles")
        .child(this.profServ.uid)
        .child("defaults")
        .update(this.paramsUpdateValues);
      this.clubServ.updateTournParams(this.tournID, this.paramsUpdateValues);
    }

    if (
      this.paramsUpdateValues.prizepool &&
      this.latestTourn.params.payoutManual
    )
      this.updateManPayouts();
  }
  getLevels(params: any, possibleBlinds: any[], allPossibleBlinds: any[]) {
    let numLevels: number = Math.round(
      (params.duration * 60) / params.levelTime
    );
    if (params.antes) numLevels--;
    let idealBlinds: any[] = [];
    let blindFactor: number;

    if (possibleBlinds.length <= numLevels) {
      numLevels = possibleBlinds.length;
      idealBlinds = possibleBlinds;
      blindFactor = 1.1;
    } else {
      let finalSmallBlind: number = possibleBlinds[possibleBlinds.length - 1];
      blindFactor = Math.pow(
        finalSmallBlind / possibleBlinds[0],
        1 / (numLevels - 1)
      );
      idealBlinds[0] = possibleBlinds[0];
      idealBlinds[numLevels - 1] = finalSmallBlind;
      for (let i = 1; i < numLevels - 1; i++) {
        idealBlinds[i] = idealBlinds[i - 1] * blindFactor;
      }
      for (let i = 1; i < numLevels - 1; i++) {
        for (var j = 1; j < possibleBlinds.length; j++) {
          if (possibleBlinds[j] < idealBlinds[i]) {
            var under = possibleBlinds[j];
          } else {
            var over = possibleBlinds[j];
            break;
          }
        }
        if (
          idealBlinds[i] - under <= over - idealBlinds[i] &&
          under > idealBlinds[i - 1]
        ) {
          idealBlinds[i] = under;
        } else {
          while (over <= idealBlinds[i - 1] && j < possibleBlinds.length - 1) {
            j++;
            over = possibleBlinds[j];
          }
          idealBlinds[i] = over;
        }
      }

      for (var doublePass = 0; doublePass < 2; doublePass++) {
        for (let i = 0; i < numLevels - 2; i++) {
          var center = (idealBlinds[i] + idealBlinds[i + 2]) / 2;
          for (let j = 1; j < possibleBlinds.length; j++) {
            if (possibleBlinds[j] < center) {
              under = possibleBlinds[j];
            } else {
              over = possibleBlinds[j];
              break;
            }
          }
          if (
            under != idealBlinds[i] &&
            (center == under ||
              idealBlinds[i + 1] - idealBlinds[i] >
                idealBlinds[i + 2] - idealBlinds[i + 1])
          ) {
            idealBlinds[i + 1] = under;
          }
          if (
            over != idealBlinds[i + 2] &&
            over != idealBlinds[i + 1] &&
            center == over
          ) {
            idealBlinds[i + 1] = over;
          }
        }
      }
    }
    // ADD 4 additional levels in case tourney goes long
    j = 0;
    if (blindFactor > 1.2) {
      blindFactor -= 0.1;
    }

    var extraBlindTarget = idealBlinds[idealBlinds.length - 1] * blindFactor;
    for (let i = numLevels; i < allPossibleBlinds.length; i++) {
      if (allPossibleBlinds[i] >= extraBlindTarget) {
        j++;
        extraBlindTarget = allPossibleBlinds[i] * blindFactor;
        idealBlinds.push(allPossibleBlinds[i]);
      }
      if (j > 3) break;
    }

    var antesArray = [];
    if (params.antes == true) {
      var possibleAntes = [];
      var currentAnte = 0;
      var chipSet = params.chipset;
      for (var ci = 0; ci < chipSet.length; ci++)
        chipSet[ci] = Number(chipSet[ci]);

      for (let j = 0; j < chipSet.length; j++) {
        var chipValue = chipSet[j];
        for (let i = 1; i < 5; i++) {
          if (chipValue * i > currentAnte) possibleAntes.push(chipValue * i);
        }
      }
      possibleAntes = possibleAntes.sort(function (a, b) {
        return a - b;
      });

      j = 0;

      for (let i = 0; i < idealBlinds.length; i++) {
        if (
          params.anteType === "big_blind_ante" ||
          params.anteType === "button_ante"
        )
          antesArray.push(idealBlinds[i] * 2);
        else if (possibleAntes[0] > idealBlinds[i] / 3) antesArray.push(0);
        else {
          var targetAnte = idealBlinds[i] / 3;
          while (possibleAntes[j] < targetAnte && j < possibleAntes.length) j++;
          if (j == 0) antesArray.push(possibleAntes[0]);
          else antesArray.push(possibleAntes[j - 1]);
        }
      }
    } else for (let i = 0; i < idealBlinds.length; i++) antesArray.push(0);

    if (params.antes && this.possibleBlinds && this.possibleBlinds.length > 0)
      this.addExtraAnteLevel(idealBlinds, antesArray);
    var newLevels = {};
    for (let i = 0; i < idealBlinds.length; i++)
      newLevels["l" + i] = {
        levelTime: params.levelTime,
        sb: idealBlinds[i],
        bb: idealBlinds[i] * 2,
        ante: antesArray[i],
        sort:
          idealBlinds[i] + (idealBlinds[i] * 2) / 100 + antesArray[i] / 1000,
        num: i + 1,
      };
    return newLevels;
  }
  calcStructure() {
    if (!this.latestTourn.params.structureManual) {
      if (this.latestTourn.params.levelTime)
        this.replaceLevels(
          this.getLevels(
            this.latestTourn.params,
            this.possibleBlinds,
            this.allPossibleBlinds
          )
        );
    }
  }
  addExtraAnteLevel(blinds: number[], antes: number[]) {
    let firstAnte: any;
    for (let a in antes) {
      if (antes[a]) {
        firstAnte = a;
        break;
      }
    }
    antes.splice(firstAnte, 0, 0);
    blinds.splice(firstAnte, 0, blinds[firstAnte]);
  }
  replaceLevels(newLevels: any) {
    if (this.latestLevels)
      for (let level of this.latestLevels) {
        if (level.afterLevel) {
          if (level.levelName) {
            newLevels[level.key] = {
              afterLevel: level.afterLevel,
              levelTime: level.levelTime,
              levelName: level.levelName,
            };
          } else {
            newLevels[level.key] = {
              afterLevel: level.afterLevel,
              levelTime: level.levelTime,
            };
          }
        }
      }
    firebase.database().ref(this.levelsPath).set(newLevels);
  }
  getPlayersCount() {
    if (this.latestTourn && this.latestTourn.params) {
      if (this.latestTourn.params.manageRegistrations) {
        if (this.latestPlayers && this.latestPlayers.length > 0)
          return this.latestPlayers.length;
        else return 0;
      } else {
        return this.latestTourn.params.actPlayers || 0;
      }
    } else return 0;
  }
  calcPlacesPaid() {
    if (
      this.latestTourn &&
      this.latestTourn.params &&
      !this.latestTourn.params.calcPlacesPaidManual
    ) {
      let before: number = this.latestTourn.params.placesPaid;
      let play: number = this.getPlayersCount();
      let paid: number;
      if (play < 6) paid = 1;
      else if (play < 9) paid = 2;
      else if (play < 15) paid = 3;
      else if (play < 100) paid = Math.round(play / 5);
      else if (play > 500) paid = Math.round(play / 10);
      else {
        var tmpPercent = 20 - (play - 100) / 40;
        paid = Math.round((play * tmpPercent) / 100);
      }
      if (before != paid) this.addParamUpdate("placesPaid", paid);
    }
  }
  calcPrizepool() {
    if (this.latestTourn.params.managePayouts) {
      let before: number = this.latestTourn.params.prizepool;
      let prizepool: number = 0;
      if (!this.latestTourn.playerCount) this.latestTourn.playerCount = 0;
      let playerCount: number = this.latestTourn.params.manageRegistrations
        ? this.latestTourn.playerCount
        : this.latestTourn.params.actPlayers;
      if (this.latestTourn.params.buyin) {
        if (this.latestTourn.params.manageRegistrations) {
          prizepool =
            (playerCount - this.latestLive.reentries) *
            this.latestTourn.params.buyin;
        } else {
          prizepool = playerCount * this.latestTourn.params.buyin;
        }
      }
      if (
        this.latestTourn.params.tournamentType != "freezeout" &&
        this.latestTourn.live
      ) {
        if (!this.latestTourn.params.rebuyCost)
          this.latestTourn.params.rebuyCost = 0;
        if (!this.latestTourn.params.reentryCost)
          this.latestTourn.params.reentryCost = 0;

        if (!this.latestTourn.params.addonCost)
          this.latestTourn.params.addonCost = 0;

        prizepool +=
          this.latestTourn.live.rebuys * this.latestTourn.params.rebuyCost +
          this.latestTourn.live.addons * this.latestTourn.params.addonCost;
      }
      if (
        this.latestTourn.params.tournamentType === "reentry_tournament" &&
        this.latestLive.reentries
      ) {
        prizepool +=
          this.latestTourn.live.reentries * this.latestTourn.params.reentryCost;
      }
      if (!this.latestTourn.params.addedPrize)
        this.latestTourn.params.addedPrize = 0;
      prizepool += this.latestTourn.params.addedPrize;
      if (
        this.latestTourn.params.bountyTournament &&
        this.latestTourn.params.bountyPerPlayer &&
        playerCount
      )
        prizepool -= this.latestTourn.params.bountyPerPlayer * playerCount;
      if (prizepool != before) {
        if (prizepool || prizepool == 0) {
          this.addParamUpdate("prizepool", prizepool);
          if (
            this.latestTourn.leagues &&
            this.latestLive &&
            this.latestLive.out
          )
            setTimeout(() => {
              for (let l in this.latestTourn.leagues)
                this.leagueServ.recalculate(l);
            });
        }
      }
    }
  }

  totalChips(ply: number, reb: number, add: number, reent: number) {
    let totalChips: number =
      (ply - reent) * this.latestTourn.params.startingStack;
    if (this.latestTourn.params.tournamentType !== "freezeout")
      totalChips += add * this.latestTourn.params.addonChips;
    if (this.latestTourn.params.tournamentType === "rebuy_tournament")
      totalChips += reb * this.latestTourn.params.rebuyChips;
    if (this.latestTourn.params.tournamentType === "reentry_tournament") {
      totalChips += reent * this.latestTourn.params.reentryChips;
    }
    if (
      this.latestTourn.params.manageRegistrations &&
      this.latestTourn.players
    ) {
      for (var p in this.latestTourn.players) {
        let player: any = this.latestTourn.players[p];
        if (player.bonusChips) totalChips += player.bonusChips;
      }
    }
    return totalChips;
  }
  isDuplicateBreak(level) {
    if (!level.afterLevel || level.afterLevel == 0) return false;
    for (let lvl of this.latestLevels) {
      if (lvl.afterLevel && lvl.afterLevel == level.afterLevel) return true;
    }
    return false;
  }
  createLevel(level: any) {
    if (!this.isDuplicateBreak(level)) this.levelsAF.push(level);
  }
  deleteLevel(key: any) {
    this.levelsAF.remove(key);
  }
  updateLevel(level: any) {
    let tempKey: string = level.key;
    delete level.key;
    this.levelsAF.update(tempKey, level);
  }
  getTemplateParams() {
    let params: any = this.util.copyObject(this.latestTourn.params);
    params.seated = null;
    params.tournStatus = null;
    params.undoIdx = null;
    return params;
  }
  saveTemplateMessage() {
    this.translate.get(["TEMPLATE_SAVED"]).subscribe((res: any) => {
      let saveMessage = res["TEMPLATE_SAVED"];
      this.snackBar.open(saveMessage, null, {
        duration: 3000,
      });
    });
  }
  saveTemplate(key: string) {
    let update = {
      params: this.getTemplateParams(),
      structure: this.util.getLevelsStructure(this.latestLevels),
    };

    this.profServ.templatesAF.update(key, update).then(() => {
      this.saveTemplateMessage();
    });
  }
  deleteTemplate(key: string) {
    this.profServ.templatesAF.remove(key);
  }
  createTemplate(name: string) {
    let template = {
      name: name,
      params: this.getTemplateParams(),
      structure: this.util.getLevelsStructure(this.latestLevels),
    };
    this.profServ.templatesAF.push(template).then(() => {
      this.saveTemplateMessage();
    });
  }
  async loadTemplate(template) {
    if (template && template.params && template.structure) {
      if (!template.params.tournamentType) {
        if (template.params.rebuyTournament)
          template.params.tournamentType = "rebuy_tournament";
        else template.params.tournamentType = "freezeout";
        delete template.params.rebuyTournament;
      }
      if (typeof template.params.chipset == "string") {
        // remove trailing comma if exists
        if (template.params.chipset.endsWith(",")) {
          template.params.chipset = template.params.chipset.slice(0, -1);
        }
        template.params.chipset = template.params.chipset
          .split(",")
          .map((chip: string) => parseFloat(chip));
      }
      await firebase.database().ref(this.paramsPath).update(template.params);
      await firebase.database().ref(this.levelsPath).set(template.structure);
    }
  }
  changeUndoIdx(delta: number) {
    this.tournRef
      .child("live")
      .child("undoIdx")
      .transaction((count) => {
        if (count) return count + delta;
        else return delta;
      });
    if (this.latestLive && this.latestLive.undoIdx)
      this.latestLive.undoIdx += delta;
    else this.latestLive.undoIdx = delta;
    return this.latestLive.undoIdx;
  }
  needsNameAppended(type: string) {
    let needAppending = [
      "REGISTER",
      "UNREGISTER",
      "ELIMINATION",
      "REBUY",
      "ADDON",
    ];
    return needAppending.indexOf(type) != -1;
  }
  needsUndo(type: string) {
    let needsUndoFunc = [
      "UNREGISTER",
      "DRAW_SEATS",
      "ADD_TABLE",
      "BREAK_TABLE",
      "BALANCE_TABLES",
      "MANUAL_SEAT_CHANGE",
    ];
    let undos: any = {
      dependent: false,
      independent: false,
    };
    if (this.latestTourn && this.latestTourn.params && this.latestLive) {
      if (type == "REBUY" || type == "ADDON" || type == "REENTRY")
        undos.independent = true;
      else if (type == "REGISTER" && this.latestLive.seated)
        undos.dependent = true;
      else if (type == "REGISTER" && !this.latestLive.seated)
        undos.independent = true;
      else if (
        type == "ELIMINATION" &&
        this.latestTourn.params.manageRegistrations
      )
        undos.dependent = true;
      else if (
        type == "ELIMINATION" &&
        !this.latestTourn.params.manageRegistrations
      )
        undos.independent = true;
      else undos.dependent = needsUndoFunc.indexOf(type) != -1;
    }
    return undos;
  }
  logEntry(type: string, params?: any) {
    if (type) {
      let entry: any = {
        type: type,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      };
      let th = this;
      this.translate
        .get([
          "CREATE_TOURNAMENT",
          "REGISTER",
          "UNREGISTER",
          "DRAW_SEATS",
          "ADD_TABLE",
          "BREAK_TABLE",
          "BALANCE_TABLES",
          "REBUY",
          "REENTRY",
          "ADDON",
          "ELIMINATION",
          "REPLAY_TOURNAMENT",
          "MANUAL_SEAT_CHANGE",
        ])
        .subscribe((res: any) => {
          entry.message = res[type];
          if (this.needsNameAppended(entry.type) && params.name) {
            entry.message += ": " + params.name;
          }
          if (params) entry.undoParams = params;

          entry.undos = this.needsUndo(entry.type);
          if (entry.undos.dependent) entry.undoIdx = this.changeUndoIdx(1);
          this.logAF.push(entry);
        });
      /*		if(type == "CREATE_TOURNAMENT" || type == "REPLAY_TOURNAMENT")
            params = this.latestTourn.params; */
      this.tracking.track(type, params);
    }
  }
  getPlayersRemaining() {
    if (this.latestTourn && this.latestLive) {
      return this.getPlayersCount() - this.latestLive.out;
    } else return null;
  }

  assignPayoutToPlayers(tourn: any, update: any, playerID?: string) {
    if (this.latestPlayers && tourn && tourn.params) {
      if (
        tourn.params.managePayouts &&
        this.latestPayouts &&
        this.latestPlayers &&
        this.latestPlayers.length
      ) {
        this.latestPlayers.map((player) => {
          for (let payout = 0; payout < this.latestPayouts.length; payout++) {
            if (player.finish !== this.latestPayouts[payout].place) {
              update["players/" + player.uid + "/payout"] = null;
            }
            if (player.finish === this.latestPayouts[payout].place) {
              update["players/" + player.uid + "/payout"] =
                this.latestPayouts[payout].award;
              break;
            }
          }
          if (
            tourn.params.bountyTournament &&
            tourn.params.bountyPerPlayer &&
            player.kos &&
            player.kos > 0 &&
            player.finish
          ) {
            let po: number = 0;
            po += tourn.params.bountyPerPlayer * player.kos;
            if (player.finish == 1) po += tourn.params.bountyPerPlayer;
            if (update["players/" + player.uid + "/payout"]) {
              update["players/" + player.uid + "/payout"] += po;
            } else {
              update["players/" + player.uid + "/payout"] = po;
            }
          }
        });
      }
    }
    return update;
  }

  elimination(playerID?: string, killerID?: string) {
    let tourn = this.latestTourn;
    let seconds = this.clock.seconds;
    let update: any = {};
    let updateLogs: any = {};
    let playerPath: string;
    var undoParams: any = {
      elimination: true,
    };
    var finish;
    if (playerID) {
      playerPath = "players/" + playerID;
      undoParams.uid = playerID;
      undoParams.name = tourn.players[playerID].dispName;
      if (tourn.players[playerID].baseuid)
        undoParams.baseuid = tourn.players[playerID].baseuid;
      finish = this.latestPlayers.length - tourn.live.out;
      update[playerPath + "/finish"] = finish;
      undoParams.finish = finish;
      if (!tourn.players[playerID].manual)
        this.api.profSetFinish(playerID, tourn.tournID, finish);
      if (tourn.players[playerID].table) {
        undoParams.table = tourn.players[playerID].table;
        undoParams.seat = tourn.players[playerID].seat;
        let tableIdx = tourn.players[playerID].table - 1;
        update[
          "tables/" +
            tableIdx.toString() +
            "/seats/" +
            (tourn.players[playerID].seat - 1).toString()
        ] = "-";
        var availArr = tourn.tables[tableIdx].avail;
        if (!availArr) availArr = [];
        availArr.push(tourn.players[playerID].seat - 1);
        update["tables/" + tableIdx.toString() + "/avail"] = availArr;
        update["tables/" + tableIdx + "/count"] =
          tourn.tables[tableIdx].count - 1;
      }
      update[playerPath + "/table"] = null;
      update[playerPath + "/seat"] = null;

      if (killerID) {
        undoParams.killerID = killerID;
        if (tourn.players[killerID].baseuid)
          undoParams.baseKillerID = tourn.players[killerID].baseuid;
        update[playerPath + "/killer"] = tourn.players[killerID].dispName;
        update[playerPath + "/killerID"] = killerID;
        if (!tourn.players[killerID].kos) {
          tourn.players[killerID].kos = 1;
          update["players/" + killerID + "/kos"] = 1;
        } else {
          tourn.players[killerID].kos++;
          update["players/" + killerID + "/kos"] = tourn.players[killerID].kos;
        }
      }
      var lFinish: number = finish;
      if (tourn.params.tournamentType == "reentry_tournament") {
        if (tourn.players[playerID].baseuid)
          update[
            "players/" + tourn.players[playerID].baseuid + "/reentryPossible"
          ] = true;
        else {
          update["players/" + playerID + "/reentryPossible"] = true;

          for (var lp in this.latestPlayers)
            if (
              !this.latestPlayers[lp].finish &&
              this.latestPlayers[lp].baseuid
            )
              lFinish--;
        }
      }

      if (this.latestTourn.leagues && !tourn.players[playerID].baseuid) {
        update[playerPath + "/leagueFinish"] = lFinish;
      }
    }
    tourn.live.out++;
    update["live/out"] = tourn.live.out;
    if (
      !this.latestLive.itm &&
      this.getPlayersCount() - tourn.live.out <= this.latestPayouts.length
    ) {
      this.latestLive.itm = true;
      update["live/itm"] = true;
    }

    if (tourn.live.seated && tourn.params.extraSeats)
      update["params/extraSeats"] = tourn.params.extraSeats - 1;
    for (var l in tourn.log) {
      var log = tourn.log[l];
      if (
        log.type == "REGISTER" &&
        log.undoParams &&
        log.undoParams.uid == playerID
      ) {
        updateLogs[l + "/undos/independent"] = false;
      }
    }
    if (finish == 2) {
      update["live/seated"] = false;
      for (var pID in tourn.players) {
        if (pID != playerID && !tourn.players[pID].finish) {
          playerPath = "players/" + pID;
          undoParams.winnerID = pID;
          update[playerPath + "/finish"] = 1;
          update["params/tournStatus"] = "Finished";
          this.latestTourn.params.tournStatus = "Finished";
          if (!tourn.players[pID].manual)
            this.api.profSetFinish(pID, tourn.tournID, 1);
          if (tourn.players[pID].table) {
            undoParams.winnerTable = tourn.players[pID].table;
            undoParams.winnerSeat = tourn.players[pID].seat;
            update[
              "tables/" +
                (tourn.players[pID].table - 1).toString() +
                "/seats/" +
                (tourn.players[pID].seat - 1).toString()
            ] = "-";
            update[
              "tables/" + (tourn.players[pID].table - 1).toString() + "/count"
            ] = tourn.tables[tourn.players[pID].table - 1].count - 1;
          }

          update[playerPath + "/table"] = null;
          update[playerPath + "/seat"] = null;

          if (tourn.params.managePayouts && this.latestPayouts) {
            let po: number = 0;
            if (this.latestPayouts[0]) po += this.latestPayouts[0].award;
            if (
              tourn.params.bountyTournament &&
              tourn.params.bountyPerPlayer &&
              tourn.players[pID].kos > 0
            )
              po += tourn.params.bountyPerPlayer * tourn.players[pID].kos;
            if (po) {
              update[playerPath + "/payout"] = po;
              undoParams.payout = po;
            }
          }
          if (
            this.latestTourn.leagues &&
            !this.latestTourn.players[pID].baseuid
          ) {
            update[playerPath + "/leagueFinish"] = 1;
          }
          for (var l in tourn.log) {
            var log = tourn.log[l];
            if (
              log.type == "REGISTER" &&
              log.undoParams &&
              log.undoParams.uid == pID
            ) {
              updateLogs[l + "/undos/independent"] = false;
            }
          }
          break;
        }
      }
      undoParams.final = true;
    }
    if (!playerID && tourn.params.actPlayers - tourn.live.out <= 1) {
      undoParams.final = true;
    }

    if (undoParams.final) {
      update["live/finishTime"] = seconds;
      this.stopTimer();
      this.clubServ.setTournFinishTime(this.tournID, seconds ? seconds : -1);
      this.clubServ.changeTournStatusInClub(this.tournID, "Finished");
      update["params/tournStatus"] = "Finished";
      this.latestTourn.params.tournStatus = "Finished";
    }

    this.tournamentAF.update(update).then((data) => {
      if (finish == 2) {
        if (pID != playerID && !tourn.players[pID].finish) {
          this.clubServ.changeTournStatusInClub(this.tournID, "Finished");
        }
      }
      if (undoParams.final || finish == 2) {
        this.removeInfo({ compType: "itm" });
      }
      if (tourn.leagues) {
        this.api.calcTournPoints(tourn.tournID, playerID);
        if (undoParams.final) this.api.calcTournPoints(tourn.tournID, pID);
      }
      if (tourn.live.out == 1) this.recycleInfo();
      this.logEntry("ELIMINATION", undoParams);
      this.api.sendEvent("EVT_PLAYER_ELIMINATED", this.profServ.latestProfile);
      setTimeout(() => {
        this.updateStacks();
        this.checkBalance();
      });
      this.loadingEliminationPlayer = false;
    });
    this.logAF.update(this.tournID, updateLogs);
  }

  reentry() {
    let update: any = {};
    if (!this.latestLive.reentries) this.latestLive.reentries = 1;
    else this.latestLive.reentries++;
    update["live/reentries"] = this.latestLive.reentries;
    this.tournamentAF.update(update).then(() => {
      this.changeParam("recalc", true);
    });
    this.logEntry("REENTRY", {
      reentry: true,
    });
    this.api.sendEvent("EVT_PLAYER_REENTERED", this.profServ.latestProfile);
  }
  rebuy(playerID?: string) {
    if (playerID) {
      if (!this.latestTourn.players[playerID].rebuys)
        this.latestTourn.players[playerID].rebuys = 0;
      this.playersAF.update(playerID, {
        rebuys: this.latestTourn.players[playerID].rebuys + 1,
      });
    }
    if (!this.latestTourn.live.rebuys) this.latestTourn.live.rebuys = 0;
    this.latestTourn.live.rebuys++;
    this.liveAF.update({ rebuys: this.latestTourn.live.rebuys }).then(() => {
      this.changeParam("recalc", true);
    });
    if (playerID)
      this.logEntry("REBUY", {
        uid: playerID,
        name: this.latestTourn.players[playerID].dispName,
      });
    else
      this.logEntry("REBUY", {
        rebuy: true,
      });
    this.api.sendEvent("EVT_PLAYER_REBOUGHT", this.profServ.latestProfile);
  }
  addon(playerID?: string) {
    if (playerID) {
      this.playersAF.update(playerID, {
        addon: 1,
      });
    }
    if (!this.latestTourn.live.addons) this.latestTourn.live.addons = 0;
    this.latestTourn.live.addons++;
    this.liveAF.update({ addons: this.latestTourn.live.addons }).then(() => {
      this.changeParam("recalc", true);
    });
    if (playerID)
      this.logEntry("ADDON", {
        uid: playerID,
        name: this.latestTourn.players[playerID].dispName,
      });
    else
      this.logEntry("ADDON", {
        addon: true,
      });
    this.api.sendEvent("EVT_PLAYER_ADDON", this.profServ.latestProfile);
  }
  unregister(uid: string, undoAction?: boolean) {
    if (uid) {
      let update: any = {};
      let updateLogs: any = {};
      var player = this.latestTourn.players[uid];
      if (player) {
        var params: any = {
          uid: uid,
          name: player.dispName,
        };
        if (player.manual) params.manual = true;
        else params.manual = false;
        if (this.latestLive.seated && player.table && player.seat) {
          params.table = this.util.copyObject(player.table);
          params.seat = this.util.copyObject(player.seat);
          var availArr = this.latestTourn.tables[player.table - 1].avail;
          if (!availArr) availArr = [];
          availArr.push(player.seat - 1);
          update["tables/" + (player.table - 1).toString() + "/avail"] =
            availArr;
          update[
            "tables/" +
              (player.table - 1).toString() +
              "/seats/" +
              (player.seat - 1).toString()
          ] = "-";
          update["tables/" + (player.table - 1).toString() + "/count"] =
            this.latestTourn.tables[player.table - 1].count - 1;
        }
        this.latestTourn.playerCount = this.latestPlayers
          ? this.latestPlayers.length - 1
          : 0;
        update["playerCount"] = this.latestTourn.playerCount;
        if (this.latestTourn.playerCount == 0) {
          update["players"] = null;
          this.latestTourn.players = null;
          this.latestPlayers = null;
        } else {
          delete this.latestTourn.players[uid];
          update["players/" + uid] = null;
        }
        if (player.rebuys && this.latestLive.rebuys) {
          this.latestLive.rebuys -= player.rebuys;
          update["live/rebuys"] = this.latestLive.rebuys;
        }
        if (player.addon && this.latestLive.addons) {
          this.latestLive.addons--;
          update["live/addons"] = this.latestLive.addons;
        }
        for (let logID in this.latestTourn.log) {
          if (
            (this.latestTourn.log[logID].type == "REGISTER" ||
              this.latestTourn.log[logID].type == "REBUY" ||
              this.latestTourn.log[logID].type == "ADDON") &&
            this.latestTourn.log[logID].undoParams &&
            this.latestTourn.log[logID].undoParams.uid == uid
          ) {
            updateLogs[logID + "/undone"] = true;
            updateLogs[logID + "/undos/dependent"] = false;
            updateLogs[logID + "/undos/independent"] = false;
          }
        }
        if (this.latestLive.out)
          for (var op in this.latestTourn.players) {
            if (this.latestTourn.players[op].finish) {
              this.latestTourn.players[op].finish--;
              update["players/" + op + "/finish"] =
                this.latestTourn.players[op].finish;
            }
            if (!player.baseuid && this.latestTourn.players[op].leagueFinish) {
              this.latestTourn.players[op].leagueFinish--;
              update["players/" + op + "/leagueFinish"] =
                this.latestTourn.players[op].leagueFinish;
            }
          }
        if (player.baseuid) {
          this.latestLive.reentries--;
          update["live/reentries"] = this.latestLive.reentries;
          update["players/" + player.baseuid + "/reentryPossible"] = true;
        }
        if (
          this.latestTourn.params.managePayouts &&
          this.latestLive.itm &&
          this.getPlayersCount() &&
          this.getPlayersCount() - this.latestLive.out >
            this.latestTourn.params.placesPaid
        ) {
          this.latestLive.itm = false;
          update["live/itm"] = false;
        }
        this.rootRef
          .child("clubs")
          .child(this.latestTourn.clubID)
          .child("tournaments")
          .child(this.tournID)
          .child("playerCount")
          .set(this.latestTourn.playerCount);
        this.tournamentAF.update(update).then(() => {
          setTimeout(() => {
            this.updateStacks(uid);
            this.changeParam("recalc", Date.now());
            this.loadingUnRegisterPlayer = false;
          }, 2500);
        });
        this.logAF.update(this.tournID, updateLogs);

        if (!params.manual) this.api.profUnregister(uid, this.tournID);

        if (this.latestLive.seated && !undoAction)
          this.logEntry("UNREGISTER", params);
        if (!this.latestTourn.params.calcPlacesPaidManual)
          this.changeParam("placesPaid", null);
      } else {
        console.log("ERROR: Player:" + uid + " not found");
      }
    } else
      this.api.appLog(
        "tournament.service",
        Date.now(),
        "unregister",
        "invalid uid",
        {
          tournID: this.tournID,
        }
      );
  }
  calcPayouts() {
    let params = this.latestTourn.params;
    if (!params.payoutManual && params.prizepool && params.managePayouts) {
      if (params.placesPaid) {
        var golden = 1.618034;
        var lastFib;
        var percentPaid;
        var initP5s;
        var lastP5s;
        var payoutsP5s;
        var ratioP5s;
        var sumP5s = 0;
        var tmpPayout;
        var p;
        var remPrizepool;
        var tmpAward;
        var sumAward;
        var sumPercent;
        var payoutsArray;
        var roundTo = 0.01;
        let buyin = params.buyin;
        let playersCount = this.getPlayersCount();
        if (buyin == 0 && playersCount) buyin = params.prizepool / playersCount;

        payoutsArray = [];

        var avgPayout = params.prizepool / params.placesPaid;
        if (params.rounding == "custom" && params.roundTo)
          roundTo = params.roundTo;
        else if (avgPayout < 10) roundTo = 0.5;
        else if (avgPayout < 20) roundTo = 1;
        else if (avgPayout < 100) roundTo = 5;
        else if (avgPayout < 200) roundTo = 10;
        else if (avgPayout < 1000) roundTo = 25;
        else if (avgPayout < 2500) roundTo = 50;
        else if (avgPayout < 7500) roundTo = 100;
        else if (avgPayout < 20000) roundTo = 250;
        else if (avgPayout < 50000) roundTo = 500;
        else roundTo = 1000;

        lastFib =
          params.prizepool /
          ((Math.pow(golden, params.placesPaid) - 1) * golden);
        percentPaid = (params.placesPaid / (params.prizepool / buyin)) * 100;

        if (percentPaid < 17) initP5s = (buyin * 24) / percentPaid - lastFib;
        else initP5s = buyin * 1.5 - lastFib;
        lastP5s = 100 / Math.pow(params.placesPaid, 0.6);
        ratioP5s = initP5s / lastP5s;
        payoutsP5s = [];

        for (p = 0; p < params.placesPaid; p++) {
          tmpPayout = (100 / Math.pow(p + 1, 0.6)) * ratioP5s;
          sumP5s = sumP5s + tmpPayout;
          payoutsP5s.push(tmpPayout);
        }

        if (sumP5s > params.prizepool) {
          ratioP5s = (ratioP5s * params.prizepool * 0.8) / sumP5s;
          sumP5s = 0;
          payoutsP5s = [];
          for (p = 0; p < params.placesPaid; p++) {
            tmpPayout = (100 / Math.pow(p + 1, 0.6)) * ratioP5s;
            sumP5s = sumP5s + tmpPayout;
            payoutsP5s.push(tmpPayout);
          }
        }

        remPrizepool = params.prizepool - sumP5s;
        tmpPayout =
          remPrizepool / ((Math.pow(golden, params.placesPaid) - 1) * golden);
        tmpAward = tmpPayout + payoutsP5s[params.placesPaid - 1];
        if (params.placesPaid > 13 && params.rounding == "auto")
          tmpAward = this.util.roundAward(tmpAward, roundTo, true);
        else tmpAward = this.util.roundAward(tmpAward, roundTo, false);
        sumAward = tmpAward;

        if (params.placesPaid == 1)
          payoutsArray.push({
            place: params.placesPaid,
            award: params.prizepool,
          });
        else
          payoutsArray.push({
            place: params.placesPaid,
            award: tmpAward,
          });

        if (params.placesPaid > 1) {
          for (p = params.placesPaid - 2; p > 0; p--) {
            tmpPayout = tmpPayout * golden;
            tmpAward = tmpPayout + payoutsP5s[p];
            if (params.placesPaid > 13 && params.rounding == "auto")
              tmpAward = this.util.roundAward(tmpAward, roundTo, true);
            else tmpAward = this.util.roundAward(tmpAward, roundTo, false);

            sumAward = sumAward + tmpAward;

            payoutsArray.push({
              place: p + 1,
              award: tmpAward,
            });
          }
          tmpAward = this.util.cleanFloat(params.prizepool - sumAward);

          payoutsArray.push({
            place: 1,
            award: tmpAward,
          });
        }

        payoutsArray.sort(function (a, b) {
          if (a.award < b.award) return 1;
          if (a.award > b.award) return -1;
          return 0;
        });
        payoutsArray = this.setPayoutPercents(payoutsArray);
        this.rootRef.child("payouts").child(this.tournID).set(payoutsArray);
      } else {
        this.rootRef.child("payouts").child(this.tournID).set(null);
      }
    }
  }
  setPayoutPercents(payoutsArray: any[]) {
    for (let p in payoutsArray) {
      payoutsArray[p].percent = math.round(
        (payoutsArray[p].award / this.latestTourn.params.prizepool) * 100,
        2
      );
    }
    return payoutsArray;
  }
  updateStacks(uid?: string) {
    var totChips;
    totChips = 0;
    let update: any = {};
    update = this.assignPayoutToPlayers(
      this.latestTourn,
      update,
      uid ? uid : ""
    );
    if (
      this.latestTourn &&
      this.latestTourn.params &&
      this.latestTourn.params.manageRegistrations &&
      this.latestPlayers
    ) {
      for (let player of this.latestPlayers) {
        const stack =
          this.latestTourn.params.tournamentType === "reentry_tournament" &&
          player.baseuid
            ? this.latestTourn.params.reentryChips
            : this.latestTourn.params.startingStack;

        if (player.uid) {
          var bought =
            stack +
            player.rebuys * this.latestTourn.params.rebuyChips +
            player.addon * this.latestTourn.params.addonChips;
          totChips += bought;
          if (!this.latestLive.started && !player.manStack)
            update["players/" + player.uid + "/stack"] = bought;
        } else
          this.api.appLog(
            "tournament.service",
            Date.now(),
            "updateStacks",
            "no uid on player object",
            {
              tournID: this.tournID,
            }
          );
      }
      this.tournamentAF.update(update).then(() => {
        this.setActualTotalChips();
      });
    }
  }
  checkBalance() {
    let needUpdate: boolean = false;
    let update: any = {};
    if (
      this.latestLive &&
      this.latestTourn &&
      this.latestLive.seated &&
      this.latestTourn.players &&
      this.latestTourn.tables &&
      this.getPlayersCount() > this.latestLive.out
    ) {
      var remaining =
        Object.keys(this.latestTourn.players).length - this.latestLive.out;
      var numTables = this.latestTourn.tables.length;
      if (numTables > 1) {
        let extraSeats = this.latestTourn.params.extraSeats;
        if (!extraSeats) extraSeats = 0;
        //Check to see if we are at the final table
        let finalTable =
          remaining + extraSeats <=
          this.latestTourn.params.numberPlayersFinalTable;
        if (this.latestLive.needFinalTableDraw != finalTable) {
          update["needFinalTableDraw"] = finalTable;
          needUpdate = true;
        }

        // Check to see if a table needs to break

        var reqTables = Math.ceil(
          (remaining + extraSeats) / this.latestTourn.params.perTable
        );
        let needBreak =
          reqTables < numTables && !this.latestLive.needFinalTableDraw;
        if (needBreak != this.latestLive.needTableBreak) {
          update["needTableBreak"] = needBreak;
          needUpdate = true;
        }

        // Check to see if tables need to be balanced
        var maxCount = 0;
        var minCount = 99;
        for (var i = 0; i < numTables; i++) {
          var tmpCnt = this.latestTourn.tables[i].count;
          if (tmpCnt > maxCount) maxCount = tmpCnt;
          if (tmpCnt < minCount) minCount = tmpCnt;
        }
        let needBalance: boolean =
          maxCount - minCount > 1 && !needBreak && !finalTable;
        if (needBalance != this.latestLive.needTableBalance) {
          update["needTableBalance"] = needBalance;
          needUpdate = true;
        }
        if (needBreak && !this.lastActionIsRegister)
          this.translate
            .get(["NEED_TABLE_BREAK", "BREAK_TABLE"])
            .subscribe((res: any) => {
              let balanceRef = this.snackBar.open(
                res["NEED_TABLE_BREAK"],
                res["BREAK_TABLE"],
                { duration: 5000 }
              );
              balanceRef.onAction().subscribe(() => {
                this.breakTable();
                this.breakTableNames();
                this.dialog.open(PlayerMovesComponent, {
                  data: {
                    tableBreaking: this.tableBreaking,
                    playerMoves: this.playerMoves,
                  },
                });
              });
            });
        if (needBalance && !this.lastActionIsRegister)
          this.translate
            .get(["UNBALANCED", "BALANCE_TABLES"])
            .subscribe((res: any) => {
              let balanceRef = this.snackBar.open(
                res["UNBALANCED"],
                res["BALANCE_TABLES"],
                { duration: 5000 }
              );
              balanceRef.onAction().subscribe(() => {
                this.balanceTables();
                this.balanceTableNames();
                this.dialog.open(PlayerMovesComponent, {
                  data: {
                    playerMoves: this.playerMoves,
                  },
                });
              });
            });
        if (finalTable && this.latestPlayers && !this.lastActionIsRegister)
          this.translate
            .get(["FINAL_TABLE_DRAW", "DRAW_SEATS"])
            .subscribe((res: any) => {
              let balanceRef = this.snackBar.open(
                res["FINAL_TABLE_DRAW"],
                res["DRAW_SEATS"],
                { duration: 5000 }
              );
              balanceRef.onAction().subscribe(() => {
                this.drawSeats();
              });
            });
        if (needUpdate) this.liveAF.update(update);
        //if (!needBalance && !needBreak && !finalTable)
        //	this.snackBar.dismiss();
      }
    }
    this.lastActionIsRegister = false;
  }
  seatAvailable(member?: any) {
    let available: boolean;
    let max: number = this.latestTourn.params.maxPlayers;
    let limit: number = this.clubServ.getPlayerLimit();
    if (limit && (!max || max > limit)) {
      this.changeParam("maxPlayers", limit);
      max = limit;
    }
    let players: number = this.getPlayersCount();
    if (
      this.latestTourn.params.tournamentType == "reentry_tournament" &&
      this.latestLive.reentries
    )
      players -= this.latestLive.reentries;
    if (
      member &&
      this.latestTourn.players &&
      this.latestTourn.players[member.uid]
    )
      players--;
    if (max && players >= max) {
      available = false;
    } else if (!this.latestLive.seated) available = true;
    else {
      available = false;
      for (var t in this.latestTourn.tables) {
        if (this.latestTourn.tables[t].avail) {
          available = true;
          break;
        }
      }
    }
    if (!available) {
      this.loadingRegister = false;
      if (limit && players >= limit)
        this.translate
          .get(["MAX_PLAYERS_YOUR_PLAN", "CLOSE"])
          .subscribe((res: any) => {
            this.dialogServ.openAlert({
              message: res["MAX_PLAYERS_YOUR_PLAN"] + ": " + limit,
              closeButton: res["CLOSE"],
            });
          });
      else if (max && players >= max)
        this.translate.get(["MAX_PLAYERS", "CLOSE"]).subscribe((res: any) => {
          this.dialogServ.openAlert({
            message: res["MAX_PLAYERS"] + ": " + max,
            closeButton: res["CLOSE"],
          });
        });
      else
        this.translate.get(["NO_SEATS", "CLOSE"]).subscribe((res: any) => {
          this.dialogServ.openAlert({
            message: res["NO_SEATS"],
            closeButton: res["CLOSE"],
          });
        });
    }
    return available;
  }
  balanceTables() {
    this.initPlayerMoves(-1);
    var undoParams = {
      players: this.util.copyObject(this.latestTourn.players),
      tables: this.util.copyObject(this.latestTourn.tables),
    };
    let tables = this.util.getObjArray(this.latestTourn.tables);
    var maxCount = 0;
    var toMove;
    for (var i = 0; i < tables.length; i++) {
      var tmpCnt = tables[i].count;
      if (tmpCnt > maxCount) {
        maxCount = tmpCnt;
        toMove = [];
        for (var plyr in this.latestPlayers)
          if (this.latestPlayers[plyr].table == i + 1)
            toMove.push(this.latestPlayers[plyr]);
      } else if (tmpCnt == maxCount) {
        for (var plyr in this.latestPlayers)
          if (this.latestPlayers[plyr].table == i + 1)
            toMove.push(this.latestPlayers[plyr]);
      }
    }
    this.util.shuffleArray(toMove);
    var mover = toMove.pop();
    mover.breakTable = mover.table;
    mover.breakSeat = mover.seat;
    this.seatPlayer(mover);
    this.checkBalance();

    this.logEntry("BALANCE_TABLES", undoParams);
  }
  initPlayerMoves(table?: number) {
    if (table) this.tableBreaking = table;
    this.playerMoves = [];
  }
  breakTableNames() {
    if (this.tableNames && this.tableNames[this.tableBreaking - 1]) {
      this.tableBreaking = this.tableNames[this.tableBreaking - 1];
    }
  }
  balanceTableNames() {
    if (this.tableNames) {
      for (let i = 0; i < this.playerMoves.length; i++) {
        if (this.tableNames[this.playerMoves[i].from.table - 1])
          this.playerMoves[i].from.table =
            this.tableNames[this.playerMoves[i].from.table - 1];
        if (this.tableNames[this.playerMoves[i].to.table - 1])
          this.playerMoves[i].to.table =
            this.tableNames[this.playerMoves[i].to.table - 1];
      }
    }
  }
  breakTable() {
    var undoParams = {
      players: this.util.copyObject(this.latestTourn.players),
      tables: this.util.copyObject(this.latestTourn.tables),
    };
    var breakTable = this.latestTourn.tables.pop();
    this.initPlayerMoves(breakTable.number);
    this.translate.get(["BREAKING_TABLE"]).subscribe((res: any) => {
      let myTable =
        this.tableNames && this.tableNames[breakTable.number - 1]
          ? this.tableNames[breakTable.number - 1]
          : breakTable.number;
      let breakMessage = res["BREAKING_TABLE"] + ": " + myTable;
      this.snackBar.open(breakMessage, null, {
        duration: 3000,
      });
    });
    var breaking = breakTable.seats;
    var director = this.latestTourn.owner;
    let update: any = {};
    update["tables/" + (breakTable.number - 1).toString()] = null;
    this.tournamentAF.update(update).then(() => {
      this.logEntry("BREAK_TABLE", undoParams);
      for (var i = 0; i < breaking.length; i++)
        if (breaking[i] != "-") {
          breaking[i].breakTable = breakTable.number;
          breaking[i].breakSeat = i + 1;
          breaking[i].table = null;
          breaking[i].seat = null;
          this.seatPlayer(breaking[i]);
        }
      this.checkBalance();
    });
  }
  register(member: any, regParams?: any) {
    this.lastActionIsRegister = true;
    let abort: boolean = false;
    if (!member.uid) member.manual = true;
    if (member.addToClub) {
      member.uid = this.clubServ.joinNew(member.memberName);
      if (!member.uid) {
        this.translate
          .get(["CLUB_MEMBER_ALREADY_EXISTS", "CLOSE"])
          .subscribe((res: any) => {
            this.dialogServ.openAlert({
              message: res["CLUB_MEMBER_ALREADY_EXISTS"],
              closeButton: res["CLOSE"],
            });
          });
        abort = true;
      } else if (member.img64) {
        let memberImgRef = firebase
          .storage()
          .ref()
          .child("clubs")
          .child(this.clubServ.clubID)
          .child("members")
          .child(member.uid);
        let th: any = this;
        memberImgRef.putString(member.img64, "data_url").then(() => {
          memberImgRef.getDownloadURL().then(function (url) {
            let update: any = {};
            update["members/" + member.uid + "/imgURL"] = url;
            th.clubServ.clubAF.update(update);
          });
        });
      }
    }
    if (!abort) {
      if (!member.uid) {
        member.guest = true;
        let newRef = this.tournRef.child("players").push();
        member.uid = newRef.key;
      }

      let update: any = {};
      if (!member.manual) member.manual = false;

      let regPlayer: any = {
        dispName: member.memberName,
        uid: member.uid,
        regStatus: "Registered",
        stack: this.latestTourn.params.startingStack,
        rebuys: 0,
        addon: 0,
        manual: member.manual,
        guest: member.guest || false,
      };
      if (this.latestTourn.players && this.latestTourn.players[member.uid]) {
        if (this.latestTourn.params.tournamentType == "reentry_tournament") {
          if (this.latestLive.reentries) this.latestLive.reentries++;
          else this.latestLive.reentries = 1;
          update["live/reentries"] = this.latestLive.reentries;
          if (!this.latestTourn.players[member.uid].bullets)
            this.latestTourn.players[member.uid].bullets = 1;
          this.latestTourn.players[member.uid].bullets++;
          if (member.uid) {
            update["players/" + member.uid + "/bullets"] =
              this.latestTourn.players[member.uid].bullets;
            update["players/" + member.uid + "/reentryPossible"] = false;
          } else
            this.api.appLog(
              "tournament.service",
              Date.now(),
              "register",
              "missing member.uid",
              {
                tournID: this.tournID,
              }
            );
          regPlayer.bullet = this.latestTourn.players[member.uid].bullets;
          regPlayer.dispName = regPlayer.dispName + "-" + regPlayer.bullet;
          regPlayer.baseuid = regPlayer.uid;
          regPlayer.uid = regPlayer.uid + "-" + regPlayer.bullet;
          regPlayer.manual = true;
        } else {
          console.log("ERROR - already registered");
          return;
        }
      }

      if (!regPlayer.manual) {
        this.api.profRegister(regPlayer.uid, this.tournID);
      }
      if (regPlayer.uid) update["players/" + regPlayer.uid] = regPlayer;
      else
        this.api.appLog(
          "tournament.service",
          Date.now(),
          "register",
          "missing regPlayer.uid",
          {
            tournID: this.tournID,
          }
        );
      this.latestTourn.playerCount = this.latestPlayers
        ? this.latestPlayers.length + 1
        : 1;
      update["playerCount"] = this.latestTourn.playerCount;
      if (this.latestLive.seated && this.latestTourn.params.extraSeats)
        update["params/extraSeats"] = this.latestTourn.params.extraSeats - 1;
      if (!this.latestLive.totalChips) this.latestLive.totalChips = 0;
      update["live/totalChips"] =
        this.latestLive.totalChips + this.latestTourn.params.startingStack;
      let wasItm: boolean = this.latestLive.itm;
      if (this.latestLive.out) {
        for (var op in this.latestTourn.players)
          if (this.latestTourn.players[op].finish) {
            this.latestTourn.players[op].finish++;
            update["players/" + op + "/finish"] =
              this.latestTourn.players[op].finish;
          }
        if (!regPlayer.baseuid)
          for (var lfp in this.latestTourn.players)
            if (this.latestTourn.players[lfp].leagueFinish) {
              this.latestTourn.players[lfp].leagueFinish++;
              update["players/" + lfp + "/leagueFinish"] =
                this.latestTourn.players[lfp].leagueFinish;
            }
        if (
          this.latestLive.itm &&
          this.getPlayersCount() - this.latestLive.out + 1 >
            this.latestTourn.params.placesPaid
        ) {
          this.latestLive.itm = false;
          update["live/itm"] = false;
        }
      }
      this.tournamentAF.update(update).then(() => {
        setTimeout(() => {
          this.updateStacks();
          if (this.latestTourn && this.latestTourn.playerCount)
            this.clubServ.setTournPlayerCount(
              this.tournID,
              this.latestTourn.playerCount
            );
        }, 2500);
        let params = {
          name: regPlayer.dispName,
          uid: regPlayer.uid,
        };
        if (this.latestLive.seated)
          if (regParams && regParams.table && regParams.seat) {
            if (regParams.undoAction) {
              this.seatPlayer(
                regPlayer,
                regParams.table - 1,
                regParams.seat - 1
              );
            } else this.seatPlayer(regPlayer, regParams.table, regParams.seat);
          } else this.seatPlayer(regPlayer);

        if (!regParams || !regParams.undoAction) {
          this.logEntry("REGISTER", params);
          this.api.sendEvent(
            "EVT_PLAYER_REGISTERED",
            this.profServ.latestProfile
          );
        }
        if (!this.latestTourn.params.calcPlacesPaidManual)
          this.changeParam("placesPaid", null);
        if (wasItm)
          setTimeout(() => {
            this.resetPlayerPayouts();
          });
        this.loadingRegister = false;
        if (this.latestLive.seated) {
          this.translate
            .get(["BEEN_SEATED", "CLOSE", "SEAT", "TABLE"])
            .subscribe((res: any) => {
              let message =
                regPlayer.dispName +
                ":" +
                " (" +
                res["TABLE"] +
                ":" +
                regPlayer.table +
                " " +
                res["SEAT"] +
                ":" +
                regPlayer.seat +
                ")";
              this.snackBar.open(message, res["CLOSE"], {
                duration: 10000,
                politeness: "assertive",
              });
            });
        }
      });
    }
  }
  resetPlayerPayouts() {
    let update: any = {};
    let needUpdate = false;
    for (var p in this.latestTourn.players) {
      let player: any = this.latestTourn.players[p];
      if (player.finish) {
        let realPayout: number;
        if (this.latestPayouts.length >= player.finish) {
          realPayout = this.latestPayouts[player.finish - 1].award;
          if (player.payout != realPayout) {
            update["players/" + p + "/payout"] = realPayout;
            needUpdate = true;
          }
        } else if (player.payout) {
          update["players/" + p + "/payout"] = null;
          needUpdate = true;
        }
      }
    }
    if (needUpdate) this.tournamentAF.update(update);
  }
  seatPlayer(
    player: any,
    manTable?: number,
    manSeat?: number,
    undoMan?: boolean
  ) {
    if (player.uid) {
      let update: any = {};
      let occupier: any;
      var fromSeat;
      // if Manual Move of seats
      if (undoMan) {
        // record existing config for undo
        var undoParams = {
          players:
            this.latestTourn && this.latestTourn.players
              ? this.util.copyObject(this.latestTourn.players)
              : {},
          tables: this.util.copyObject(this.latestTourn.tables),
        };
        this.logEntry("MANUAL_SEAT_CHANGE", undoParams);
      }
      //manTable = Number(manTable);
      //manSeat = Number(manSeat);

      // If player is already seated
      if (player.table && player.seat) {
        if (this.latestTourn.tables[player.table - 1]) {
          if (player.swapped) player.swapped = false;
          else {
            // Don't clear seat info when swapped with another player
            fromSeat = {
              table: Number(player.table),
              seat: Number(player.seat),
            };
            // set table entry to empty
            update[
              "tables/" + (player.table - 1) + "/seats/" + (player.seat - 1)
            ] = "-";
            this.latestTourn.tables[player.table - 1].seats[player.seat - 1] =
              "-";
            // if no available array, create it
            if (!this.latestTourn.tables[player.table - 1].avail)
              update["tables/" + (player.table - 1) + "/avail"] = [];
            else
              update["tables/" + (player.table - 1) + "/avail"] =
                this.latestTourn.tables[player.table - 1].avail;
            // Add players seat they are leaving to the available list
            update["tables/" + (player.table - 1) + "/avail"].push(
              player.seat - 1
            );
            this.latestTourn.tables[player.table - 1].avail =
              update["tables/" + (player.table - 1) + "/avail"];
            // clear their current seat
            update["players/" + player.uid + "/table"] = null;
            update["players/" + player.uid + "/seat"] = null;
          }
          //decrement the player count
          this.latestTourn.tables[player.table - 1].count--;
          update["tables/" + (player.table - 1) + "/count"] =
            this.latestTourn.tables[player.table - 1].count;
        }
        player.table = null;
        player.seat = null;
      }
      var table;
      var avSeats: any[];
      var seat;
      // If seat assigned manually
      if (manTable || manTable == 0) {
        // if destination seat is occupied
        if (
          this.latestTourn.tables[manTable].seats[manSeat] &&
          this.latestTourn.tables[manTable].seats[manSeat].dispName
        ) {
          // switch them with the seat they are coming from, or seat them anywhere
          occupier = this.util.copyObject(
            this.latestTourn.tables[manTable].seats[manSeat]
          );
          occupier.swapped = true;
        }
        table = this.util.copyObject(this.latestTourn.tables[manTable]);
        avSeats = table.avail;
        if (!avSeats) avSeats = [];
        var manIdx = avSeats.indexOf(manSeat);
        if (manIdx != -1) avSeats.splice(manIdx, 1);
        seat = manSeat + 1;
      } else {
        var min = 99;
        var minTables;
        for (var t = 0; t < this.latestTourn.tables.length; t++) {
          if (this.latestTourn.tables[t].count < min) {
            min = this.latestTourn.tables[t].count;
            minTables = [this.latestTourn.tables[t]];
          } else if (this.latestTourn.tables[t].count == min)
            minTables.push(this.latestTourn.tables[t]);
        }
        this.util.shuffleArray(minTables);
        table = minTables.pop();
        avSeats = table.avail;
        this.util.shuffleArray(avSeats);
        seat = avSeats.pop() + 1;
      }
      update["tables/" + (table.number - 1) + "/avail"] = avSeats;
      let playerPath = "players/" + player.uid;
      update[playerPath + "/table"] = table.number;
      update[playerPath + "/seat"] = seat;
      update[playerPath + "/seatSort"] = table.number + seat / 10;
      update[playerPath + "/regStatus"] = "Seating";
      this.latestTourn.tables[table.number - 1].count++;
      update["tables/" + (table.number - 1) + "/count"] =
        this.latestTourn.tables[table.number - 1].count;
      player.seat = seat;
      player.table = table.number;
      player.seatSort = table.number + seat / 10;
      update["tables/" + (table.number - 1) + "/seats/" + (seat - 1)] = player;
      this.tournamentAF.update(update).then(() => {
        if (occupier) {
          if (fromSeat) {
            setTimeout(() => {
              this.seatPlayer(occupier, fromSeat.table - 1, fromSeat.seat - 1);
            });
          } else {
            setTimeout(() => {
              this.seatPlayer(occupier);
            });
          }
        }
        this.loadingMovePlayer = false;
        if (!occupier) this.checkBalance();
      });
      if (this.tableBreaking) {
        let playerMove: any = {
          dispName: player.dispName,
          imgURL: this.clubServ.getMemberImage(player.baseuid || player.uid),
          from: {
            table: player.breakTable,
            seat: player.breakSeat,
          },
          to: {
            table: player.table,
            seat: player.seat,
          },
        };
        this.playerMoves.push(playerMove);
        this.balanceTableNames();
      }
    } else
      this.api.appLog(
        "tournament.service",
        Date.now(),
        "seatPlayer",
        "missing player.uid",
        {
          tournID: this.tournID,
          player: player,
        }
      );
  }

  drawSeats() {
    this.loadingDrawSeats = true;
    let update: any = {};
    var undoParams: any = {
      players:
        this.latestTourn && this.latestTourn.players
          ? this.util.copyObject(this.latestTourn.players)
          : {},
    };
    if (this.latestTourn.tables)
      undoParams.tables = this.util.copyObject(this.latestTourn.tables);
    else {
      undoParams.tables = null;
      this.changeRegisterUndos(false);
    }
    var playerArr = [];
    for (var plyr in this.latestTourn.players)
      if (!this.latestTourn.players[plyr].finish)
        playerArr.push(this.latestTourn.players[plyr]);
    this.util.shuffleArray(playerArr);
    var pCnt = playerArr.length;
    var extra = 0;
    if (this.latestTourn.params.extraSeats)
      extra = this.latestTourn.params.extraSeats;
    var seatCnt = pCnt + extra;
    if (!this.latestTourn.params.perTable) this.latestTourn.params.perTable = 9;
    var numTables;
    var tables = [];
    if (!this.latestTourn.live.needFinalTableDraw)
      numTables = Math.ceil(seatCnt / this.latestTourn.params.perTable);
    else
      numTables = Math.ceil(
        seatCnt / this.latestTourn.params.numberPlayersFinalTable
      );

    for (var t = 0; t < numTables; t++) {
      var table = {
        number: t + 1,
        count: 0,
        seats: [],
        avail: [],
      };
      if (!this.latestTourn.live.needFinalTableDraw) {
        for (var s = 0; s < this.latestTourn.params.perTable; s++) {
          table.seats.push("-");
          table.avail.push(s);
        }
      } else {
        for (
          var s = 0;
          s < this.latestTourn.params.numberPlayersFinalTable;
          s++
        ) {
          table.seats.push("-");
          table.avail.push(s);
        }
      }
      tables.push(table);
    }
    for (var i = 0; i < pCnt; i++) {
      if (playerArr && playerArr.length) {
        var tab = i % numTables;
        var seat = tables[tab].avail.shift();
        tables[tab].seats[seat] = this.util.copyObject(playerArr.pop());
        tables[tab].seats[seat].table = tab + 1;
        tables[tab].seats[seat].seat = seat + 1;
        tables[tab].count++;
        let playerID = tables[tab].seats[seat].uid;
        if (playerID) {
          let playerPath = "players/" + playerID;
          update[playerPath + "/table"] = tab + 1;
          update[playerPath + "/seat"] = seat + 1;
          update[playerPath + "/seatSort"] = tab + 1 + (seat + 1) / 10;
          update[playerPath + "/regStatus"] = "Seating";
          if (this.latestLive.needFinalTableDraw)
            update[playerPath + "/finalTable"] = true;
        } else
          this.api.appLog(
            "tournament.service",
            Date.now(),
            "drawSeats",
            "missing playerID",
            {
              tournID: this.tournID,
            }
          );
      }
    }

    // Ajouter tableNames comme les exemples ici
    // update['tableNames'] = obj{}

    update["tables"] = tables;
    update["live/seated"] = true;
    update["params/tournStatus"] = "Seating";

    update["live/needTableBalance"] = false;
    update["live/needTableBreak"] = false;
    update["live/needFinalTableDraw"] = false;
    this.tournamentAF.update(update).then((res) => {
      this.mainIndex = 3;
      this.loadingDrawSeats = false;
      this.logEntry("DRAW_SEATS", undoParams);
      this.api.sendEvent("EVT_SEATS_DRAWN", this.profServ.latestProfile);
    });
  }
  stacksUpdated() {
    this.liveAF
      .update({
        stacksUpdated: true,
      })
      .then(() => {
        this.recycleInfo();
      });
  }
  recycleInfo() {
    this.needInfosInit = true;
    this.initInfoCycle();
  }
  initInfoCycle() {
    if (this.needInfosInit) {
      if (this.latestTourn && this.latestTourn.params) {
        this.needInfosInit = false;
        this.infoArr = [];
        this.infoArr.push({
          compType: "blinds",
          data: {
            repeat: true,
            base: true,
          },
        });
        if (
          this.latestTourn.params.manageRegistrations &&
          (this.latestLive.stacksUpdated || this.latestLive.out)
        )
          this.infoArr.push({
            compType: "leaderboard",
            data: {
              repeat: true,
              base: true,
            },
          });
        if (this.latestTourn.params.managePayouts)
          this.infoArr.push({
            compType: "payouts",
            data: {
              repeat: true,
              base: true,
            },
          });
        if (
          this.latestTourn.params.tournamentType != "freezeout" &&
          this.latestLive &&
          (this.latestLive.reentries ||
            this.latestLive.rebuys ||
            this.latestLive.addons)
        )
          this.infoArr.push({
            compType: "rebuys",
            data: {
              repeat: true,
              base: true,
            },
          });

        if (
          this.latestLive.itm &&
          this.latestTourn.params.tournStatus != "Finished"
        )
          this.infoArr.push({
            compType: "itm",
            data: {
              repeat: true,
              base: true,
            },
          });
        if (
          this.latestTourn.params.numberPlayersFinalTable &&
          this.latestTourn.playerCount - this.latestTourn.live.out ==
            this.latestTourn.params.numberPlayersFinalTable
        )
          this.infoArr.push({
            compType: "ft",
            data: {
              repeat: true,
              base: true,
            },
          });
        else if (
          !this.latestTourn.params.numberPlayersFinalTable &&
          this.latestTourn.playerCount - this.latestTourn.live.out ==
            this.latestTourn.params.perTable
        )
          this.infoArr.push({
            compType: "ft",
            data: {
              repeat: true,
              base: true,
            },
          });
        const cnt = this.latestTourn.params.manageRegistrations
          ? this.latestTourn.playerCount
          : this.latestTourn.params.actPlayers;
        if (
          cnt - this.latestTourn.live.out - 1 ==
          this.latestTourn.params.placesPaid
        ) {
          this.infoArr.push({
            compType: "moneyBubble",
            data: {
              repeat: true,
              base: true,
            },
          });
        }

        if (
          this.latestTourn.params.numberPlayersFinalTable &&
          this.latestTourn.playerCount - this.latestTourn.live.out - 1 ==
            this.latestTourn.params.numberPlayersFinalTable
        )
          this.infoArr.push({
            compType: "ftBubble",
            data: {
              repeat: true,
              base: true,
            },
          });
        else if (
          !this.latestTourn.params.numberPlayersFinalTable &&
          this.latestTourn.playerCount - this.latestTourn.live.out - 1 ==
            this.latestTourn.params.perTable
        )
          this.infoArr.push({
            compType: "ftBubble",
            data: {
              repeat: true,
              base: true,
            },
          });
        this.nextInfo();
      }
    }
    if (this.infoCycle) clearInterval(this.infoCycle);
    this.infoCycle = setInterval(() => {
      this.cycleInfo();
    }, 10000);
  }
  cycleInfo() {
    if (this.infoArr && this.infoArr.length > this.infoWidth) {
      let removed: any = this.infoArr.shift();
      if (removed.data && removed.data.repeat) {
        this.infoArr.push({
          compType: removed.compType,
          data: removed.data,
        });
      }
    }
    this.nextInfo();
  }
  nextInfo() {
    this.infoLoop.next(this.infoArr);
  }
  addInfoIfMissing(compType: string, data: any) {
    if (this.infoArr) {
      var found: boolean = false;
      for (var i = 0; i < this.infoArr.length; i++) {
        if (this.infoArr[i].compType == compType) {
          found = true;
          break;
        }
      }
      if (!found) this.addInfo(compType, data);
    }
  }
  addInfo(compType: string, data: any) {
    if (this.infoArr) {
      let infoObj: any = {
        compType: compType,
        data: data,
      };
      let insertIndex: number = 0;
      if (this.infoArr.length <= this.infoWidth) this.infoArr.push(infoObj);
      else {
        let infoLength: number = this.infoWidth;
        if (this.infoArr.length < this.infoWidth)
          infoLength = this.infoArr.length;
        for (var i = this.infoWidth; i <= this.infoArr.length; i++)
          if (this.infoArr[i] && this.infoArr[i].data.base) break;
        this.infoArr.splice(i, 0, infoObj);
      }
    }
    this.cycleInfo();
  }
  removeInfo(params: any) {
    if (this.infoArr && this.infoArr.length) {
      for (var i = 0; i < this.infoArr.length; i++) {
        if (this.infoArr[i].compType == params.compType) {
          this.infoArr.splice(i, 1);
          this.cycleInfo();
        }
      }
    }
  }
  updateInfoEliminationFinish(delta: number) {
    for (var i = 0; i < this.infoArr.length; i++) {
      if (
        this.infoArr[i].compType == "elimination" &&
        this.infoArr[i].data &&
        this.infoArr[i].data.undoParams
      ) {
        this.infoArr[i].data.undoParams.finish += delta;
      }
    }
  }
  initClock() {
    let th = this;
    this.clockDelta(function (delta) {
      th.delta = delta;
    });
  }
  clockDelta(callback: any) {
    var init;
    var deltaRef = this.baseRef.child("deltas").push();
    deltaRef.on("value", function (snapshot) {
      if (snapshot.val() && init) {
        var end = Date.now();
        var rt = end - start;
        var server = snapshot.val();
        var delta = end - server - rt / 2;
        callback(delta);
      }
      if (!init) init = true;
    });
    var start = Date.now();
    deltaRef.set(firebase.database.ServerValue.TIMESTAMP);
  }
  tick() {
    this.tickSubj.next(true);
    this.checkRotate();
    if (this.latestLive) {
      if (
        this.latestLive.running &&
        this.delta &&
        this.structure &&
        this.structure.length
      ) {
        var now = Date.now();
        var elapsed = Math.floor(
          (now - this.latestLive.started - this.delta) / 1000
        );
        while (elapsed < 0) {
          this.delta += elapsed * 100;
          elapsed = Math.floor(
            (now - this.latestLive.started - this.delta) / 1000
          );
        }
        this.clock.seconds = this.latestLive.seconds + elapsed;
        if (
          this.clock.seconds > this.latestLive.seconds &&
          this.clock.seconds
        ) {
          this.setClock();
        }
      } else {
        if (
          this.clock.seconds != this.latestLive.seconds ||
          !this.clock.timeText
        ) {
          this.clock.seconds = this.latestLive.seconds;
          if (
            this.clock.seconds >= 0 &&
            this.structure &&
            this.structure.length
          )
            this.setClock();
        }
      }
    }
  }
  setClock() {
    if (
      this.structure &&
      this.structure.length &&
      this.clock.seconds != undefined
    ) {
      var lvl = 0;
      var thisIsTheEnd = false;
      var sec;
      let overrun: boolean = false;
      if (
        !this.latestLive.running &&
        this.latestLive.seconds != this.clock.seconds
      )
        this.clock.seconds = this.latestLive.seconds;
      sec = this.clock.seconds;
      this.findNextBreak();

      var level = this.structure[lvl];
      while (sec >= level.levelTime * 60) {
        sec -= this.structure[lvl].levelTime * 60;
        if (thisIsTheEnd) {
          this.clock.seconds -= sec;
          this.latestLive.seconds = this.clock.seconds;
          if (this.latestLive.running) this.stopTimer(this.clock.seconds);
          overrun = true;
        }
        if (lvl < this.structure.length - 1) lvl++;
        if (lvl == this.structure.length - 1) thisIsTheEnd = true;
        level = this.structure[lvl];
      }
      this.clock.runningTime = this.util.timeText(this.clock.seconds);

      this.baseSec = this.clock.seconds - sec;
      /* TODO
      if (!this.clock.sliding) {
        this.slider.value = sec || 0;
        this.sliderLevel.levelTime = this.structure[lvl].levelTime;
        this.slider.options.ceil = this.sliderLevel.levelTime * 60 || 1200;
      } */

      if (this.clock.level != lvl) {
        if (this.latestLive.running && this.clock.level == lvl - 1) {
          if (!this.clock.mute) this.audio.play("change");
          this.tracking.track("Tick Next Level", {
            from: this.clock.level,
            to: lvl,
            seconds: this.clock.seconds,
          });
          if (
            this.clock.level >= 2 &&
            !this.latestTourn.params.real &&
            this.profServ.latestProfile.locationData &&
            this.profServ.latestProfile.locationData.traits.ip_address
          ) {
            this.real(this.profServ.latestProfile.locationData);
            this.tracking.track("Log Real Tournament", this.latestTourn.params);
          }
        }
        this.clock.level = lvl;
      }
      level = this.structure[lvl];
      this.setBlinds();
      if (!thisIsTheEnd) {
        if (
          this.clock.timeSec == 60 &&
          !this.clock.mute &&
          level.levelTime != 1 &&
          !this.clock.minutePlayed
        ) {
          this.clock.minutePlayed = true;
          this.audio.play("minute");
        } else if (this.clock.timeSec != 60) this.clock.minutePlayed = false;

        if (
          this.clock.timeSec < 6 &&
          this.clock.timeSec > 0 &&
          this.clock.secondPlayed != this.clock.timeSec &&
          !this.clock.mute
        ) {
          this.clock.secondPlayed = this.clock.timeSec;
          this.audio.play("second");
        }
      }
      this.clock.timeSec = level.levelTime * 60 - sec;
      if (overrun) this.clock.timeSec = 0;
      this.clock.time = this.util.timeText(this.clock.timeSec);

      this.clock.nextBreakSec = this.nextBreak - this.clock.seconds;
      if (this.clock.nextBreakSec < 0) this.clock.nextBreak = "--:--";
      else this.clock.nextBreak = this.util.timeText(this.clock.nextBreakSec);
    }
  }

  setBlinds() {
    if (!this.clock.level) this.clock.level = 0;
    var level = this.structure[this.clock.level];
    if (level) {
      if (
        this.latestTourn &&
        this.latestTourn.params.anteType != "standard" &&
        this.latestTourn.params.antes_half &&
        this.getPlayersCount() - this.latestLive.out < 6
      ) {
        level = this.util.copyObject(this.structure[this.clock.level]);
        level.ante = math.round(level.ante / 2, 2);
      }
      var kLevel = this.util.kBlinds(level);
      this.clock.sb = kLevel.sb;
      this.clock.bb = kLevel.bb;
      this.clock.ante = kLevel.ante;
      this.clock.breakStart = level.breakStart;
      this.clock.levelName = level.levelName;
    }
  }
  findNextBreak() {
    var nb = 999999;
    for (var i = 0; i < this.structure.length; i++)
      if (
        this.structure[i].breakStart > this.clock.seconds &&
        this.structure[i].breakStart < nb
      ) {
        nb = this.structure[i].breakStart;
      }
    if (nb == 999999) this.nextBreak = 0;
    else this.nextBreak = nb;
  }
  real(locationData: any) {
    if (
      this.latestTourn &&
      this.latestTourn.params &&
      !this.latestTourn.params.real
    ) {
      let th = this;
      this.tournRef
        .child("params")
        .child("real")
        .transaction(
          function (real) {
            if (!real) {
              return true;
            } else return;
          },
          function (error, commit) {
            if (commit) th.realConfirm(locationData);
          }
        );
    }
  }
  statManPlayers() {
    if (
      !this.latestTourn.params.actPlayers ||
      this.latestTourn.params.expPlayers > this.latestTourn.params.actPlayers
    )
      return this.latestTourn.params.expPlayers;
    else return this.latestTourn.params.actPlayers;
  }
  realConfirm(locData: any) {
    let statsRef = this.baseRef.child("tourney_stats");
    let globalsRef = this.baseRef.child("globals");
    let statObj: any = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      ip_address: locData.traits.ip_address,
      startingStack: parseInt(this.latestTourn.params.startingStack),
      rebuyTournament:
        this.latestTourn.params.tournamentType == "rebuy_tournament",
      tournamentType: this.latestTourn.params.tournamentType,
      level_time: this.latestTourn.params.levelTime,
      running_time: this.latestTourn.params.duration,
      chip1: this.latestTourn.params.chipset[0] ?? null,
      chip2: this.latestTourn.params.chipset[1] ?? null,
      chip3: this.latestTourn.params.chipset[2] ?? null,
      chip4: this.latestTourn.params.chipset[3] ?? null,
      chip5: this.latestTourn.params.chipset[4] ?? null,
      locationData: locData,
      tournID: this.tournID,
    };
    if (this.profServ.uid) statObj.uid = this.profServ.uid;
    if (this.profServ.latestSubscription) statObj.subscriber = true;
    else statObj.subscriber = false;
    if (this.latestTourn.params.tournamentType != "freezeout") {
      statObj.rebuys = this.latestTourn.params.rebuys || 0;
      statObj.addons = this.latestTourn.params.addons || 0;
    }
    if (this.latestTourn.params.tournamentType == "reentry_tournament")
      statObj.reentries = this.latestTourn.params.reentries || 0;

    if (this.latestTourn.params.manageRegistrations) {
      if (
        this.latestTourn.players &&
        Object.keys(this.latestTourn.players).length > 1
      )
        statObj.players = Object.keys(this.latestTourn.players).length;
      else statObj.players = this.statManPlayers();
    } else statObj.players = this.statManPlayers();

    statObj.players = parseInt(statObj.players);

    if (this.latestTourn.params.antes) statObj.antes = true;
    else statObj.antes = false;

    statObj.startTime = this.latestLive.started;
    statObj.endTime =
      this.latestLive.started +
      1000 * 60 * 60 * this.latestTourn.params.duration;
    statsRef.push(statObj);
    var dayDate = this.util.shedTime(statObj.startTime);

    globalsRef.child("tournamentCount").transaction(function (count) {
      if (count) return count + 1;
      else return 1;
    });
    globalsRef
      .child("tournaments")
      .child(dayDate)
      .transaction(function (count) {
        if (count) return count + 1;
        else return 1;
      });
    globalsRef
      .child("audience")
      .child(dayDate)
      .transaction(function (count) {
        if (count) return parseInt(count) + statObj.players;
        else return statObj.players;
      });
    globalsRef
      .child("countryTournaments")
      .child(locData.country)
      .child(dayDate)
      .transaction(function (count) {
        if (count) return count + 1;
        else return 1;
      });
    globalsRef
      .child("countryAudience")
      .child(locData.country)
      .child(dayDate)
      .transaction(function (count) {
        if (count) return parseInt(count) + statObj.players;
        else return statObj.players;
      });

    // partitioned data
    let partPrefix = statObj.subscriber ? "subscribers" : "free";
    globalsRef
      .child(partPrefix)
      .child("tournamentCount")
      .transaction(function (count) {
        if (count) return count + 1;
        else return 1;
      });
    globalsRef
      .child(partPrefix)
      .child("tournaments")
      .child(dayDate)
      .transaction(function (count) {
        if (count) return count + 1;
        else return 1;
      });
    globalsRef
      .child(partPrefix)
      .child("audience")
      .child(dayDate)
      .transaction(function (count) {
        if (count) return parseInt(count) + statObj.players;
        else return statObj.players;
      });
    globalsRef
      .child(partPrefix)
      .child("countryTournaments")
      .child(locData.country)
      .child(dayDate)
      .transaction(function (count) {
        if (count) return count + 1;
        else return 1;
      });
    globalsRef
      .child(partPrefix)
      .child("countryAudience")
      .child(locData.country)
      .child(dayDate)
      .transaction(function (count) {
        if (count) return parseInt(count) + statObj.players;
        else return statObj.players;
      });
  }
  checkRotate() {
    // TODO
  }
  nextLevel() {
    let newSec: number = 0;
    this.clock.level++;
    for (var i = 0; i < this.clock.level; i++) {
      newSec += this.structure[i].levelTime * 60;
    }
    this.setTimer(newSec);
    this.setBlinds();
    this.tracking.track("Next Level", this.latestLive);
    this.api.sendEvent("EVT_NEXT_LEVEL", this.profServ.latestProfile);
  }
  prevLevel() {
    if (this.clock.level <= 3 && this.latestTourn.params.real) {
      this.changeParam("real", false);
    }
    if (this.clock.level < 2) {
      this.clock.level = 0;
      this.stopTimer(0);
    } else {
      var newSec = 0;
      this.clock.level--;
      for (var i = 0; i < this.clock.level; i++) {
        newSec += this.structure[i].levelTime * 60;
      }
      this.stopTimer(newSec);
    }
    this.setBlinds();
    this.tracking.track("Prev Level", this.latestLive);
  }
  validTournament() {
    let player_limit: number = this.clubServ.getPlayerLimit();
    let registeredPlayers: number = 0;
    if (player_limit) {
      if (this.latestTourn.params.manageRegistrations)
        registeredPlayers =
          this.latestTourn.playerCount - (this.latestLive.reentries || 0);
      else registeredPlayers = this.latestTourn.params.actPlayers;
      if (registeredPlayers <= player_limit) return true;
      else return false;
    } else return true;
  }
  notValidMessage() {
    this.dialog.open(MaxPlayersComponent);
  }
  startTimer() {
    if (this.validTournament()) {
      let update: any = {};
      update["live/running"] = true;
      update["live/started"] = firebase.database.ServerValue.TIMESTAMP;
      if (this.latestTourn.params.tournStatus != "Running") {
        update["params/tournStatus"] = "Running";
        // TODO Statuses
        // for (var pID in this.players)
        //	this.players[pID].regStatus = "Playing";
        this.api.sendEvent(
          "EVT_TOURNAMENT_STARTED",
          this.profServ.latestProfile
        );
      }
      this.tournamentAF.update(update);
      this.clubServ.changeTournStatusInClub(this.tournID, "Running");
    } else this.notValidMessage();
  }
  stopTimer(seconds?: number) {
    const newSeconds = seconds >= 0 ? seconds : this.clock.seconds;
    this.latestLive.running = false;
    this.latestLive.seconds = newSeconds;
    let update: any = {};
    update["running"] = false;
    update["seconds"] = newSeconds;
    this.liveAF.update(update);
  }
  setTimer(seconds: number) {
    let update: any = {};
    update["started"] = firebase.database.ServerValue.TIMESTAMP;
    update["seconds"] = seconds;
    this.liveAF.update(update);
  }
  changeClockSeconds(seconds: number) {
    seconds = this.structure[this.clock.level].levelTime * 60 - seconds;
    for (var i = 0; i < this.clock.level; i++)
      seconds += this.structure[i].levelTime * 60;
    this.setTimer(seconds);
  }
  addTable() {
    this.loadingAddTable = true;
    var undoParams = {
      players:
        this.latestTourn && this.latestTourn.players
          ? this.util.copyObject(this.latestTourn.players)
          : {},
      tables: this.util.copyObject(this.latestTourn.tables),
    };
    if (this.latestLive.seated && this.latestTourn.tables) {
      let tables = this.util.getObjArray(this.latestTourn.tables);
      var t = tables.length;
      var table = {
        number: t + 1,
        count: 0,
        seats: [],
        avail: [],
      };
      for (var s = 0; s < this.latestTourn.params.perTable; s++) {
        table.seats.push("-");
        table.avail.push(s);
      }
      tables.push(table);
      this.tournRef
        .child("tables")
        .set(tables)
        .then((data) => {
          this.checkBalance();
          this.loadingAddTable = false;
        });
    }

    this.logEntry("ADD_TABLE", undoParams);
    this.api.sendEvent("EVT_ADD_TABLE", this.profServ.latestProfile);
  }
  changeRegisterUndos(independent: boolean) {
    let tournUpdate: any = {};
    for (var entryID in this.latestTourn.log)
      if (this.latestTourn.log[entryID].type == "REGISTER") {
        tournUpdate[entryID + "/dependent"] = false;
        tournUpdate[entryID + "/independent"] = independent;
      }
    this.logAF.update(this.tournID, tournUpdate);
  }
  resetSeating(params: any) {
    let tournUpdate: any = {};
    tournUpdate.players = params.players;
    if (params.tables) tournUpdate.tables = params.tables;
    else {
      tournUpdate.tables = null;
      tournUpdate["live/seated"] = false;
      this.changeRegisterUndos(true);
    }
    this.tournamentAF.update(tournUpdate).then((data) => {
      if (this.tables) this.checkBalance();
    });
  }
  undoRebuyAddon(type: string, params: any) {
    let tournUpdate: any = {};
    if (type == "REBUY") {
      if (params.uid) {
        this.latestTourn.players[params.uid].rebuys--;
        tournUpdate["players/" + params.uid + "/rebuys"] =
          this.latestTourn.players[params.uid].rebuys;
      }
      this.latestLive.rebuys--;
      tournUpdate["live/rebuys"] = this.latestLive.rebuys;
    }
    if (type == "ADDON") {
      if (params.uid)
        tournUpdate["players/" + params.uid + "/addon"] =
          this.latestTourn.players[params.uid].addon - 1;
      tournUpdate["live/addons"] = this.latestLive.addons - 1;
    }
    if (type == "REENTRY") {
      this.latestLive.reentries--;
      tournUpdate["live/reentries"] = this.latestLive.reentries;
    }
    this.tournamentAF.update(tournUpdate).then((data) => {
      this.changeParam("recalc", true);
    });
  }
  undoElimination(params: any) {
    let tournUpdate: any = {};
    let tournUpdateLogs: any = {};
    let profUpdate: any;
    if (params.uid) {
      if (!this.latestTourn.players[params.uid].manual) {
        this.api.profUndoElimination(params.uid, this.latestTourn.tournID);
      }
      tournUpdate["players/" + params.uid + "/finish"] = null;
      tournUpdate["players/" + params.uid + "/leagueFinish"] = null;
      tournUpdate["players/" + params.uid + "/payout"] = null;
      if (params.killerID) {
        this.latestTourn.players[params.killerID].kos--;
        tournUpdate["players/" + params.killerID + "/kos"] =
          this.latestTourn.players[params.killerID].kos;
        tournUpdate["players/" + params.uid + "/killerID"] = null;
        tournUpdate["players/" + params.uid + "/killer"] = null;
      }

      if (params.final) {
        if (params.table) tournUpdate["live/seated"] = true;
        tournUpdate["players/" + params.winnerID + "/payout"] = null;
        tournUpdate["players/" + params.winnerID + "/finish"] = null;
        tournUpdate["players/" + params.winnerID + "/leagueFinish"] = null;
        if (this.latestTourn && this.latestTourn.leagues)
          tournUpdate["players/" + params.winnerID + "/points"] = null;
      }
      if (this.latestTourn && this.latestTourn.leagues)
        tournUpdate["players/" + params.uid + "/points"] = null;
      if (
        this.latestTourn &&
        this.latestTourn.params &&
        this.latestTourn.params.tournamentType == "reentry_tournament"
      )
        if (this.latestTourn.players[params.uid].baseuid)
          tournUpdate[
            "players/" +
              this.latestTourn.players[params.uid].baseuid +
              "/reentryPossible"
          ] = false;
        else tournUpdate["players/" + params.uid + "/reentryPossible"] = false;
    } else
      this.api.appLog(
        "tournament.service",
        Date.now(),
        "undoElimination",
        "missing params.uid",
        {
          tournID: this.tournID,
          params: params,
        }
      );
    this.latestLive.out--;
    tournUpdate["live/out"] = this.latestLive.out;
    if (
      this.latestTourn.params.managePayouts &&
      this.latestLive.itm &&
      this.getPlayersCount() &&
      this.getPlayersCount() - this.latestLive.out >
        this.latestTourn.params.placesPaid
    ) {
      this.latestLive.itm = false;
      tournUpdate["live/itm"] = false;
      this.removeInfo({ compType: "itm" });
    }
    if (params.final) {
      this.startTimer();
      tournUpdate["live/finishTime"] = null;
      this.clubServ.setTournFinishTime(this.tournID, null);
    }
    for (var l in this.latestTourn.log) {
      var log = this.latestTourn.log[l];
      if (
        log.type == "REGISTER" &&
        log.undoParams &&
        log.undoParams.uid == params.uid
      )
        tournUpdateLogs[l + "/undos/independent"] = true;
    }
    if (params && params.finish === 2) {
      let winnerId;
      for (let l in this.latestTourn.players) {
        if (this.latestTourn.players[l].finish === 1) {
          winnerId = this.latestTourn.players[l].uid;
          break;
        }
      }
      for (var l in this.latestTourn.log) {
        var log = this.latestTourn.log[l];
        if (
          log.type == "REGISTER" &&
          log.undoParams &&
          log.undoParams.uid == winnerId
        )
          tournUpdateLogs[l + "/undos/independent"] = true;
      }
    }
    this.tournamentAF.update(tournUpdate).then(() => {
      if (params.table)
        this.seatPlayer(
          this.latestTourn.players[params.uid],
          params.table - 1,
          params.seat - 1,
          false
        );
      if (params.winnerTable)
        this.seatPlayer(
          this.latestTourn.players[params.winnerID],
          params.winnerTable - 1,
          params.winnerSeat - 1,
          false
        );
      this.recalculateLeagues();
    });
    this.logAF.update(this.tournID, tournUpdateLogs);
  }
  logUndo(entry: any) {
    if (entry.type == "REGISTER") {
      this.unregister(entry.undoParams.uid, true);
    }
    if (
      entry.type == "DRAW_SEATS" ||
      entry.type == "ADD_TABLE" ||
      entry.type == "BREAK_TABLE" ||
      entry.type == "BALANCE_TABLES" ||
      entry.type == "MANUAL_SEAT_CHANGE"
    ) {
      this.resetSeating(entry.undoParams);
    }
    if (
      entry.type == "REBUY" ||
      entry.type == "ADDON" ||
      entry.type == "REENTRY"
    )
      this.undoRebuyAddon(entry.type, entry.undoParams);
    if (entry.type == "ELIMINATION") this.undoElimination(entry.undoParams);
    if (entry.type == "UNREGISTER") {
      if (!entry.undoParams.manual) entry.undoParams.manual = false;
      let member = {
        uid: entry.undoParams.uid,
        memberName: entry.undoParams.name,
        manual: entry.undoParams.manual,
      };
      this.register(member, {
        table: entry.undoParams.table,
        seat: entry.undoParams.seat,
        undoAction: true,
      });
    }
    let tournUpdate: any = {};
    tournUpdate["/undoParams"] = null;
    tournUpdate["/undone"] = true;
    if (entry.undoIdx) this.changeUndoIdx(-1);
    tournUpdate["/undoIdx"] = null;
    tournUpdate["/undo"] = null;
    tournUpdate["/undos"] = {
      dependent: false,
      independent: false,
      test: "test",
    };
    this.logAF.update(entry.key, tournUpdate);
    this.tracking.track("UNDO" + entry.type);
  }
  updateAward(val: number, payout: any) {
    let percent: number = math.round(
      (val / this.latestTourn.params.prizepool) * 100,
      2
    );
    this.localPayouts[payout.place - 1].percent = percent;
    this.payoutsAF
      .update((payout.place - 1).toString(), { award: val, percent: percent })
      .then((data) => {
        this.updateAwardDeltas();
      });
  }
  updatePercent(val: number, payout: any) {
    let award: number = math.round(
      (val / 100) * this.latestTourn.params.prizepool,
      2
    );
    this.localPayouts[payout.place - 1].award = award;
    this.payoutsAF
      .update((payout.place - 1).toString(), { award: award, percent: val })
      .then((data) => {
        this.updateAwardDeltas();
      });
  }
  updateAwardDeltas() {
    if (this.localPayouts) {
      let awardTot: number = 0;
      let percentTot: number = 0;
      for (var i = 0; i < this.localPayouts.length; i++) {
        let payout = this.localPayouts[i];
        awardTot += payout.award;
        percentTot += payout.percent;
      }
      this.awardDelta = math.round(
        awardTot - this.latestTourn.params.prizepool,
        2
      );
      this.percentDelta = math.round(percentTot - 100, 2);
    }
  }
  setLocalPayouts() {
    if (this.latestPayouts && this.latestTourn && this.latestTourn.params) {
      this.localPayouts = this.util.copyArray(this.latestPayouts);
      for (var i = 0; i < this.localPayouts.length; i++) {
        this.localPayouts[i].percent = math.round(
          (this.localPayouts[i].award / this.latestTourn.params.prizepool) *
            100,
          2
        );
      }
      this.updateAwardDeltas();
    } else {
      this.needPayoutsInit = true;
    }
  }
  /*   hideLokit() {
    if (this.auth && this.auth.lokit && this.auth.lokit.hideUI)
      this.auth.lokit.hideUI()

  }
  showLokit() {
    if (this.auth && this.auth.lokit && this.auth.lokit.showUIButton)
      this.auth.lokit.showUIButton()
  } */
  recalculateLeagues() {
    if (this.latestTourn && this.latestTourn.leagues)
      for (let l in this.latestTourn.leagues)
        this.api.recalculateLeagueStandings(l);
  }
  updatePointsMultipliers(multipliers: any[]) {
    let update: any = {};
    for (let i = 0; i < multipliers.length; i++) {
      const mult = multipliers[i];
      if (!mult.multiplier) mult.multiplier = 1;
      if (mult.leagueID) {
        update["leagues/" + mult.leagueID + "/multiplier"] = mult.multiplier;
      }
    }
    this.tournamentAF.update(update).then(() => {
      this.recalculateLeagues();
    });
  }
  addBonusChips(player: any, bonusChips: number) {
    if (player && player.uid && bonusChips) {
      let update: any = {
        bonusChips: (player.bonusChips || 0) + bonusChips,
        stack: (player.stack || 0) + bonusChips,
      };
      this.rootRef
        .child("tournaments")
        .child(this.tournID)
        .child("players")
        .child(player.uid)
        .update(update)
        .then(() => {
          this.changeParam("recalc", true);
        });
    }
  }
  updatePlayerCount(count) {
    this.rootRef
      .child("tournaments")
      .child(this.tournID)
      .child("playerCount")
      .set(count);
    this.rootRef
      .child("clubs")
      .child(this.latestTourn.clubID)
      .child("tournaments")
      .child(this.tournID)
      .child("playerCount")
      .set(count);
  }
  checkForGhosts() {
    if (this.latestTourn && this.latestTourn.players) {
      let update;
      let count = 0;
      for (let p in this.latestTourn.players) {
        if (!this.latestTourn.players[p].uid) {
          if (!update) update = {};
          update[p] = null;
        } else count++;
      }
      if (update) {
        this.rootRef
          .child("tournaments")
          .child(this.tournID)
          .child("players")
          .update(update);
        if (this.latestTourn.playerCount != count)
          this.updatePlayerCount(count);
      }
    }
  }
}
